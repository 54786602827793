import React from "react";
import useWindowDimensions from "../functions/useWindowDimensions";
import Navbar from "../components/Navbar";
import colors from "../config/colors";
import downloadios from "../assets/images/downloadios-min.png";
import downloadandroid from "../assets/images/downloadandroid.png";

export default function Links() {
  const { height, width } = useWindowDimensions();

  let styles = {
    header: {
      fontFamily: "Montserrat-Bold",
      marginTop: 50,
      color: colors.colorOne,
      textAlign: "center",
    }
  };

  return (
    <div style={{ height: height }}>
      <Navbar alternate={true} />
      <div style={{ alignItems: "center" }}>
        <h1 style={styles.header}>Download Studytable</h1>
        <div style={{ flexDirection: "row", marginTop: 25 }}>
          <div onClick={() => window.open("https://apps.apple.com/us/app/studytable/id1270274007")} style={{marginRight: 7, cursor: "pointer"}}>
            <img
              style={{ height: 50, width: (50 * 4491) / 1552 }}
              src={downloadios}
            />
          </div>

          <div onClick={() => window.open("https://play.google.com/store/apps/details?id=com.tudrmedia.TuDr")} style={{marginLeft: 7, cursor: "pointer"}}>
            <img
              style={{ height: 50, width: (50 * 564) / 168 }}
              src={downloadandroid}
            />
          </div>

        </div>

        <h1 style={{...styles.header, marginTop: 100}}>Leave Us a Review</h1>
        <div onClick={() => window.open("https://apps.apple.com/us/app/appname/id1270274007?action=write-review")} style={{height: 40, backgroundColor: colors.orange, maxWidth: "90%", width: 300, marginTop: 20, borderRadius: 10, display: "flex", alignItems: "center", justifyContent: "center", boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", cursor: "pointer"}}>
          <p style={{color: "white", fontFamily: "Montserrat-SemiBold", fontSize: 14}}>
            Rate on App Store
          </p>
        </div>

      </div>
    </div>
  );
}

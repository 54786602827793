import React, { useState, useRef } from "react";
import useWindowDimensions from "../functions/useWindowDimensions";
import Sidebar from "../dashboardComponents/Sidebar";
import Moderation from "../dashboardComponents/Moderation";
import Events from "../dashboardComponents/Events";
import Analytics from "../dashboardComponents/Analytics";
import RequireDesktop from "../dashboardComponents/RequireDesktop";
import { SIDEBAR_WIDTH, COLLAPSED_SIDEBAR_WIDTH } from "../config/constants";
import LoadingBar from "react-top-loading-bar";
import colors from "../config/colors";

export default function Dashboard(props) {
  const [tab, setTab] = useState("analytics");
  const loadingBarRef = useRef();
  const { height, width } = useWindowDimensions();

  const styles = {
    container: {
      flexDirection: "row",
      backgroundColor: "#F5F7FA",
      width: "100%",
      minHeight: "100vh",
    },
  };

  if (width > 730) {
    return (
      <div style={styles.container}>
        <LoadingBar
          color={colors.orange}
          ref={loadingBarRef}
          height={5}
        />
        <Sidebar tab={tab} setTab={setTab} logout={props.logout} />
        <div style={{ width: "100%", paddingLeft: width > 1000 ? SIDEBAR_WIDTH : COLLAPSED_SIDEBAR_WIDTH }}>
          <div style={{ alignItems: "center" }}>
            {tab === "analytics" && <Analytics userData={props.userData} loadingBarRef={loadingBarRef} />}
            {tab === "events" && <Events userData={props.userData} loadingBarRef={loadingBarRef} />}
          </div>
        </div>
      </div>
    );
  } else {
    return <RequireDesktop />;
  }
}

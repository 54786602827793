import React from "react";
import Logo from "../assets/images/Applogo.svg";
import StudyTable from "../assets/images/Logo.svg";
import { VscGraphLine } from "react-icons/vsc";
import { MdLogout } from "react-icons/md";
import { BsCalendarEvent } from "react-icons/bs";
import { SIDEBAR_WIDTH } from "../config/constants";
import colors from "../config/colors";
import useWindowDimensions from "../functions/useWindowDimensions";

const PADDING = 60;
const SELECTED_COLOR = "#39486C";

export default function Sidebar(props) {
  const { height, width } = useWindowDimensions();

  const tab = props.tab;
  const setTab = props.setTab;

  const styles = {
    container: {
      height: "100vh",
      backgroundColor: colors.navy,
      position: "fixed",
      paddingTop: 50,
      width: SIDEBAR_WIDTH,
    },
    tab: {
      width: "100%",
      paddingTop: 15,
      paddingBottom: 15,
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: PADDING,
      cursor: "pointer",
    },
    logoContainer: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      paddingLeft: PADDING - 5,
      paddingRight: PADDING,
    },

    collapsedLogoContainer: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    collapsedContainer: {
      height: "100vh",
      backgroundColor: colors.navy,
      position: "fixed",
      paddingTop: 25,
      width: 75,
    },
    collapsedTab: {
      width: "100%",
      paddingTop: 15,
      paddingBottom: 15,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
  };

  if (width > 1000) {
    return (
      <div style={styles.container}>
        <div style={styles.logoContainer}>
          <img style={{ width: 32, height: 32 }} src={Logo} />
          <img style={{ marginLeft: 15, height: 24 }} src={StudyTable} />
        </div>
        <div style={{ justifyContent: "space-between", height: "100%" }}>
          <div>
            <div
              style={{
                ...styles.tab,
                marginTop: "15%",
                backgroundColor: tab === "analytics" ? SELECTED_COLOR : null,
              }}
              onClick={() => {
                setTab("analytics");
              }}
            >
              <VscGraphLine
                style={{ marginRight: 15 }}
                color={tab === "analytics" ? colors.orangeTwo : "white"}
              />
              <p
                style={{
                  fontFamily: "Montserrat-Medium",
                  color: tab === "analytics" ? colors.orangeTwo : "white",
                }}
              >
                Analytics
              </p>
            </div>
            <div
              style={{
                ...styles.tab,
                backgroundColor: tab === "events" ? SELECTED_COLOR : null,
              }}
              onClick={() => {
                setTab("events");
              }}
            >
              <BsCalendarEvent
                style={{ marginRight: 15 }}
                color={tab === "events" ? colors.orangeTwo : "white"}
              />
              <p
                style={{
                  fontFamily: "Montserrat-Medium",
                  color: tab === "events" ? colors.orangeTwo : "white",
                }}
              >
                Events
              </p>
            </div>
            {/*
              <div
                style={{
                  ...styles.tab,
                  backgroundColor: tab === "moderation" ? SELECTED_COLOR : null,
                }}
                onClick={() => {
                  setTab("moderation");
                }}
              >
                <VscReport
                  style={{ marginRight: 15 }}
                  color={tab === "moderation" ? colors.orangeTwo : "white"}
                />
                <p
                  style={{
                    fontFamily: "Montserrat-Medium",
                    color: tab === "moderation" ? colors.orangeTwo : "white",
                  }}
                >
                  Moderation
                </p>
              </div>
              */}
          </div>

          <div
            style={{
              ...styles.tab,
              marginBottom: "10%",
            }}
            onClick={() => {
              props.logout();
            }}
          >
            <MdLogout style={{ marginRight: 15 }} color={"white"} />
            <p style={{ fontFamily: "Montserrat-Medium", color: "white" }}>
              Logout
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={styles.collapsedContainer}>
        <div style={styles.collapsedLogoContainer}>
          <img style={{ width: 32, height: 32 }} src={Logo} />
        </div>

        <div style={{ justifyContent: "space-between", height: "100%" }}>
          <div>
            <div
              style={{
                ...styles.collapsedTab,
                marginTop: 50,
                backgroundColor: tab === "analytics" ? SELECTED_COLOR : null,
              }}
              onClick={() => {
                setTab("analytics");
              }}
            >
              <VscGraphLine
                size={20}
                color={tab === "analytics" ? colors.orangeTwo : "white"}
              />
            </div>
            <div
                style={{
                  ...styles.collapsedTab,
                  backgroundColor: tab === "events" ? SELECTED_COLOR : null,
                }}
                onClick={() => {
                  setTab("events");
                }}
              >
                <BsCalendarEvent
                  size={20}
                  color={tab === "events" ? colors.orangeTwo : "white"}
                />
              </div>
            {/*
              <div
                style={{
                  ...styles.collapsedTab,
                  backgroundColor: tab === "moderation" ? SELECTED_COLOR : null,
                }}
                onClick={() => {
                  setTab("moderation");
                }}
              >
                <VscReport
                  size={20}
                  color={tab === "moderation" ? colors.orangeTwo : "white"}
                />
              </div>
            */}
          </div>

          <div
            style={{
              ...styles.collapsedTab,
              marginBottom: "10%",
            }}
            onClick={() => {
              props.logout();
            }}
          >
            <MdLogout size={20} color={"white"} />
          </div>
        </div>
      </div>
    );
  }
}

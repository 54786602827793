import React, { useEffect, useState } from "react";
import colors from "../config/colors";
import { get, ref, query, getDatabase } from "firebase/database";
import { FaUserCheck, FaUser, FaClipboardCheck, FaBookOpen, FaEye } from "react-icons/fa";
import { BsFillChatFill } from "react-icons/bs";
import dashboardStyles from "../config/dashboardStyles";

export default function Analytics(props) {
  const db = getDatabase();
  const [data, setData] = useState({});
  const [semesters, setSemesters] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    getSemesters();
  }, []);

  const getStatsPathId = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    var statsId;

    if (month < 5 || (month === 5 && day < 21)) {
        statsId = `1${year}`;
    } else if (month < 8 || (month === 8 && day < 20)) {
        statsId = `2${year}`;
    } else {
        statsId = `3${year}`;
    }
    
    console.log(statsId)
    return statsId;
}

  const getSemesters = () => {
    const date = new Date();

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    var startYear = 2023;
    var startMonth = 5;
    var startDay = 21;

    const semesters = [];
    while (startYear <= year) {
      if ((startYear === year) && (startMonth > month || (startMonth === month && day > startDay))) {
        break;
      }

      if (startMonth === 1) {
        semesters.push({
          id: `1${startYear}`,
          label: `${startYear} Spring`
        });
        startMonth = 5;
        startDay = 21;
      } else if (startMonth <= 5) {
        semesters.push({
          id: `2${startYear}`,
          label: `${startYear} Summer`
        });
        startMonth = 8;
        startDay = 20;
      } else if (startMonth <= 8) {
        semesters.push({
          id: `3${startYear}`,
          label: `${startYear} Winter`
        });
        startMonth = 1;
        startDay = 16;
        startYear += 1;
      }
    }

    if (!semesters.length) {
      semesters.push( {
        id: "22023",
        label: "2023 Summer"
      })
    }

    setSelectedSemester(semesters[0]);
    console.log(semesters[0])
    setSemesters(semesters);
  }

  const styles = {
    circle: {
      width: 60,
      height: 60,
      borderRadius: 50,
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 25,
      marginTop: 25,
    },
    headerRow: {
      width: "100%", 
      flexDirection: "row", 
      alignItems: "center",
      marginTop: 50
    },
    number: {
      fontFamily: "Montserrat-Bold",
      color: colors.colorOne,
    },
    numbersCard: {
      minWidth: 200,
      flex: 1,
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      marginTop: 20,
      justifyContent: "flex-start",
    },
    numbersContainer: {
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
      flexWrap: "wrap",
    },
    numbersDescription: {
      fontFamily: "Montserrat-Medium",
      marginLeft: 30,
      marginRight: 30,
      marginTop: 5,
      color: colors.darkGrey,
      marginBottom: 40,
    },
    numbersHeader: {
      fontFamily: "Montserrat-Bold",
      color: colors.darkGrey,
      marginTop: 10,
    },
    numbersSection: {
      flexDirection: "row",
      flex: 1,
    },
    selectInput: {
      height: 45, 
      width: 300, 
      borderRadius: 10, 
      fontFamily: "Montserrat-Medium", 
      paddingLeft: 5, 
      paddingRight: 5,  
      borderBottom: "0px solid white",  
      borderLeft: "0px solid white",  
      borderTop: "0px solid white", 
      borderRight: "10px solid transparent",  
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)"
    }
  };

  const getData = async () => {
    try {
      const res = await fetch(
        "https://us-central1-prod-9d48e.cloudfunctions.net/api/stats",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            universityId: "studytable-ivytech-muncie"
          }),
        }
      );
 
      const data = await res.json();
      console.log(data.stats)
      setData(data.stats);
    } catch (err) {
      return {};
    }
  };

  
  
  return (
    <div style={dashboardStyles.container}>
      <div style={styles.headerRow}>
        <h2 style={{...dashboardStyles.header, marginTop: 0}}>Analytics</h2>
        <select value={selectedSemester} onChange={(e) => setSelectedSemester(e.target.value)} style={styles.selectInput}>
          {semesters.map((item, index) => (
            <option value={item.id}>{item.label}</option>
          ))}
        </select>
      </div>

      {(
        <>
            <div style={styles.numbersContainer}>
            <div style={styles.numbersSection}>
              <div style={styles.numbersCard}>
                <div
                  style={{
                    ...styles.circle,
                    backgroundColor: "rgba(255, 142, 3, 0.2)",
                  }}
                >
                  <FaUser size={22} color={colors.orange} />
                </div>

                <h1 style={styles.number}>
                  {data[selectedSemester?.id]?.accountsActive?.toLocaleString("en-US") || 0}
                </h1>
                <p style={styles.numbersHeader}>Active Students</p>

                <small>
                  <p style={styles.numbersDescription}>
                    Students that have signed into Studytable.
                  </p>
                </small>
              </div>

              <div style={styles.numbersCard}>
                <div
                  style={{
                    ...styles.circle,
                    backgroundColor: "rgba(255, 142, 3, 0.2)",
                  }}
                >
                  <BsFillChatFill size={22} color={colors.orange} />
                </div>

                <h1 style={styles.number}>
                  {data[selectedSemester?.id]?.messagesSent?.toLocaleString("en-US") || 0}
                </h1>
                <p style={styles.numbersHeader}>Messages Sent</p>

                <small>
                  <p style={styles.numbersDescription}>
                    Message sent in both class and individual chats.
                  </p>
                </small>
              </div>
            </div>

            <div style={styles.numbersSection}>
              <div style={styles.numbersCard}>
                <div
                  style={{
                    ...styles.circle,
                    backgroundColor: "rgba(255, 142, 3, 0.2)",
                  }}
                >
                  <FaEye size={24} color={colors.orange} />
                </div>

                <h1 style={styles.number}>
                  {data[selectedSemester?.id]?.impressions?.toLocaleString("en-US") || 0}
                </h1>
                <p style={styles.numbersHeader}>Student Impressions</p>

                <small>
                  <p style={styles.numbersDescription}>
                    Impressions inside the Studytables.
                  </p>
                </small>
              </div>

              <div style={styles.numbersCard}>
                <div
                  style={{
                    ...styles.circle,
                    backgroundColor: "rgba(255, 142, 3, 0.2)",
                  }}
                >
                  <FaClipboardCheck size={22} color={colors.orange} />
                </div>

                <h1 style={styles.number}>
                  {data[selectedSemester?.id]?.tasksCreated?.toLocaleString("en-US") || 0}
                </h1>
                <p style={styles.numbersHeader}>Tasks Created</p>

                <small>
                  <p style={styles.numbersDescription}>
                    Tasks created through the in-app planner.
                  </p>
                </small>
              </div>
            </div>
          </div>


          <div style={styles.numbersContainer}>
            <div style={styles.numbersSection}>
              <div style={styles.numbersCard}>
                <div
                  style={{
                    ...styles.circle,
                    backgroundColor: "rgba(255, 142, 3, 0.2)",
                  }}
                >
                  <FaBookOpen size={22} color={colors.orange} />
                </div>

                <h1 style={styles.number}>
                  {data[selectedSemester?.id]?.snInteractions?.toLocaleString("en-US") || 0}
                </h1>
                <p style={styles.numbersHeader}>Success Network <br/>Interactions</p>

                <small>
                  <p style={styles.numbersDescription}>
                    Actions taken within the in-app resource hub.
                  </p>
                </small>
              </div>

              <div style={{...styles.numbersCard, visibility: "hidden"}}>
          
              </div>
            </div>
            <div style={styles.numbersSection}>
              <div style={{...styles.numbersCard, visibility: "hidden"}}/>

              <div style={{...styles.numbersCard, visibility: "hidden"}}/>
            </div>
          </div>
        </>

      )}
      
    </div>
  );
}

import StudyTableLogo from "../assets/images/Applogo.svg";
import StudyTableWordNavy from "../assets/images/LogoNavy.svg";
import StudyTableWordWhite from "../assets/images/Logo.svg";

export default function Logo(props) {
  const isAlternate = props.alternate;
  return (
    <>
      <img style={{ width: 40, height: 40 }} src={StudyTableLogo} />
      <img style={{ marginLeft: 12, height: 28 }} src={isAlternate ? StudyTableWordWhite : StudyTableWordNavy } />
    </>
  );
}

import colors from "../config/colors";

export default {
  backCard: {
    marginTop: 25,
    marginBottom: 50,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 10,
    padding: 30,
    alignItems: "center"
  },
  btn: {
    height: 40,
    borderRadius: 10,
    width: 120,
    backgroundColor: colors.orange,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 5,
    cursor: "pointer",
  },
  btnText: {
    fontFamily: "Montserrat-Bold",
    color: "white",
  },
  container: {
    width: "95%",
    paddingBottom: 50,
  },
  header: {
    fontFamily: "Montserrat-Bold",
    color: colors.colorOne,
    textAlign: "left",
    marginTop: 50,
    width: "100%"
  },
  headerDescription: {
    fontFamily: "Montserrat-Medium",
    color: colors.darkGrey,
    marginTop: 5,
    textAlign: "left",
  },
  infoText: {
    fontFamily: "Montserrat-Medium",
    color: colors.darkGrey,
    marginTop: 5,
    textAlign: "left",
  },
  input: {
    width: 350,
    height: 40,
    borderRadius: 10,
    color: colors.darkGrey,
    maxWidth: "70%",
  },
  statusText: {
    fontFamily: "Montserrat-SemiBold",
    marginTop: 15,
  },
  tableColumnHeader: {
    fontFamily: "Montserrat-Bold",
    color: colors.colorOne,
    textAlign: "left"
  },
  tableDataText: {
    fontFamily: "Montserrat-Medium",
    color: colors.darkGrey,
    textAlign: "left",
  },
  tableRow: {
    minHeight: 55,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "0.5px solid " + colors.mediumGrey,
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  tableBottomSection: {
    height: 55,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#F2F7FA",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  tableTopSection: {
    height: 55,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#F2F7FA",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  
};

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import blob from "../assets/images/blob.png";
import DemoThread from "../assets/images/DemoThread.png";
import DemoChat from "../assets/images/DemoChat2.png";
import DemoPlanner from "../assets/images/DemoPlanner3.png";
import DemoResources from "../assets/images/DemoResources.png";
import useWindowDimensions from "../functions/useWindowDimensions";
import { BsApple } from "react-icons/bs";
import { AiFillAndroid } from "react-icons/ai";
import preview from "../assets/images/preview.png";
import Footer from "../components/Footer";
import HomeBackground from "../assets/images/HomeBackground.jpg";
import Logo from "../components/Logo";
import DemoThread2 from "../assets/images/DemoThread2.png";
import Modal from 'react-modal';
import Screenshot1 from "../assets/images/Screenshot1.png";
import Vimeo from '@u-wave/react-vimeo';
import { FaPlay } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

Modal.setAppElement('#root');


export default function Home() {
  const { height, width } = useWindowDimensions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const SECTION_TWO_BREAKPOINT = 1050;


  const NAVBAR_HEIGHT = 100;
  const SECTION_ONE_BREAKPOINT = 1100;

  const SECTION_THREE_MAX_WIDTH = 1500;
  const SECTION_THREE_BREAKPOINT = 1000;

  const styles = {

    darkOverlay: {
      position: "absolute",
      width: "100vw",
      background: "linear-gradient(to right, #040A14, #040A14 40%, transparent 120%)",
      height: "100vh"
    },
    sectionOne: {
      width: "100vw",
      height: "100vh",
    },
    sectionOneBackgroundContainer: {
      flexDirection: "row",
      width: "100vw",
      height: "100vh",
      justifyContent: "flex-end",
      position: "absolute",
      zIndex: -1,
      backgroundColor: "#040A14",
    },
    sectionOneImg: {
      width: "70%",
      height: "100vh",
      background: `url(${HomeBackground})`,
      backgroundSize: "cover",
      backgroundPosition: "top",
    },
    logoContainer: {
      display: "flex",
      flexDirection: "row",
      marginLeft: width < 500 ? "7%" : "4%",
      alignItems: "center",
      cursor: "pointer",
      marginTop: 30
    },









    section: {
      width: "100vw",
      height: "100vh",
    },
    blob: {
      height: height + NAVBAR_HEIGHT,
      overflowX: "hidden",
      position: "absolute",
      top: -NAVBAR_HEIGHT,
      right:
        width > 1600
          ? 0
          : width > 1500
          ? -height * 0.05
          : width > 1300
          ? -height * 0.2
          : width > 1150
          ? -height * 0.35
          : -height * 0.45,
      display: width < SECTION_ONE_BREAKPOINT ? "none" : null,
    },
    container: {
      width: "100vw",
    },


    // section three
    sectionThreeRow: {
      width: "100%",
      flexDirection: width < SECTION_THREE_BREAKPOINT ? "column" : "row",
      marginTop: width < SECTION_THREE_BREAKPOINT ? 50 : 25,
      marginBottom: width < SECTION_THREE_BREAKPOINT ? 50 : 25,
      justifyContent: "center",
      alignItems: "center",
    },
    sectionThreeTextContainer: {
      width: width < SECTION_THREE_BREAKPOINT ? "100%" : "50%",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: width < 1000 ? 0 : "5%",
    },
    sectionThreeInnerTextContainer: {
      width: width > 450 ? 450 : "100%",
      maxWidth: width * 0.8,
    },
    sectionThree: {
      maxWidth: SECTION_THREE_MAX_WIDTH,
    },
    sectionThreeHeader: {
      fontFamily: "Montserrat-Bold",
      color: "white",
      textAlign: "left",
      lineHeight: 1.3,
    },
    sectionThreeDivider: {
      width: 100,
      height: 5,
      backgroundColor: "#445989",
      alignSelf: "flex-start",
      marginTop: 20,
      marginBottom: 20,
    },
    sectionThreeText: {
      textAlign: "left",
      color: "white",
      lineHeight: 1.5,
      fontFamily: "Montserrat-Medium",
      opacity: 0.65
    },
    sectionThreeImgContainer: {
      width: width < SECTION_THREE_BREAKPOINT ? "110%" : "50%",
      maxWidth: width < SECTION_THREE_BREAKPOINT ? 650 : null,
      alignItems: "center",
    },

    // section 4
    sectionFour: {
      width: "100%",
      backgroundColor: "rgba(255,255,255, 0.05)", 
      height: 350,
      justifyContent: "center",
      alignItems: "center",
    },
    sectionFourHeader: {
      fontFamily: "Montserrat-Bold",
      lineHeight: 1.3,
      color: "white",
      maxWidth: "80%",
    },
    sectionFourBtn: {
      backgroundColor: "orange",
      borderRadius: 10,
      paddingRight: 30,
      paddingLeft: 30,
      paddingTop: 10,
      paddingBottom: 10,
      marginTop: 30,
      cursor: "pointer"
    },
    sectionFourBtnText: {
      fontFamily: "Montserrat-Bold",
      color: "white",
    },
    
  };

  return (
    <div style={styles.container}>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          content: {
            border: "0px solid transparent",
            backgroundColor: "transparent",
            right: 0,
            left: 0
          },
          overlay: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: 'rgba(0, 0, 0, 0.85)'
          }
        }}
      >
        <div 
          onClick={() => setIsModalOpen(false)} 
          style={{width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}
        >
          <div style={{backgroundColor: "#081327"}}>
            <Vimeo
              video="802942077"
              autoplay
              width={width > 600 ? width * 0.75 : width}
              height={width > 600 ? width * 0.75 * 9/16 : width * 9/16}
            />
          </div>
        </div>
      </Modal>
      
      <div style={styles.sectionOne}>
        <div
          style={styles.logoContainer}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <Logo alternate={true} />
        </div>


        <div style={styles.sectionOneBackgroundContainer}>
            <div style={styles.sectionOneImg}/>
            <div style={{...styles.darkOverlay, background: width > 500 ? "linear-gradient(to right, #040A14, #040A14 30%, rgba(4, 10, 20, 0.5) 100%)" : "linear-gradient(to right, #040A14, #040A14 30%, rgba(4, 10, 20, 0.8) 100%)" }}/>
        </div>

        <div style={{height: "100%", justifyContent: "center", paddingLeft: "10%"}}>
          <h1 style={{color: "white", fontFamily: "Montserrat-ExtraBold", fontSize: 55, textAlign: "left", lineHeight: 1.15}}>
            Create Connected <br/> Classrooms
          </h1>
          <p style={{color: "white", fontFamily: "Montserrat-Medium", opacity: 0.65, width: 550, maxWidth: "90%", textAlign: "left", marginTop: 20}}>
            Launch digital chatrooms for classmates to communicate, build relationships, and help each other through coursework.
          </p>
          <div onClick={() => setIsModalOpen(true)} style={{backgroundColor: "#445989", cursor: "pointer", flexDirection: "row", width: 200, height: 50, borderRadius: 10, justifyContent: "center", alignItems: "center", marginTop: 50}}>
            <FaPlay style={{marginRight: 10}} color="white"/>
            <p style={{color: "white", fontFamily: "Montserrat-Bold"}}>View Demo</p>
          </div>
        </div>
      </div>

      <div style={{backgroundColor: "#040A14", width: "100vw"}}>
        <div style={{marginTop: 200, marginBottom: 200}}>
          <div style={{width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
            <div style={{justifyContent: "space-between", width: width > SECTION_TWO_BREAKPOINT ? 1050 : "95%", minHeight: 450, backgroundColor: "rgba(255,255,255, 0.07)", boxShadow: "inset 0 0 5px white, 0 0 5px white", borderRadius: 25, border: "1px solid white", flexDirection: width > SECTION_TWO_BREAKPOINT ? "row" : "column"}}>
              <div style={{height: "100%", minHeight: 450, justifyContent: "center", marginLeft: "8%", marginRight: 20}}>
                <h1 style={{color: "white", fontFamily: "Montserrat-ExtraBold", fontSize: 45, textAlign: width > SECTION_TWO_BREAKPOINT ? "left" : "center"}}>
                  Are You Building <br/> Student Community?
                </h1>
                <p style={{color: "white", fontFamily: "Montserrat-Medium", opacity: 0.65, textAlign: "left", marginTop: 20, textAlign: width > SECTION_TWO_BREAKPOINT ? "left" : "center"}}>
                  See how universities are launching Studytable to build new engagement for their students.
                </p>
                <div style={{width: "100%", alignItems: width < SECTION_TWO_BREAKPOINT ? "center" : undefined}}>
                  <div onClick={() => window.open("mailto:wes@studytable.com")} style={{backgroundColor: "#445989", width: 200, height: 50, borderRadius: 10, justifyContent: "center", alignItems: "center", marginTop: 40, flexDirection: "row", cursor: "pointer"}}>
                    <FiMail size={20} style={{marginRight: 7}} color="white"/>
                    <p style={{color: "white", fontFamily: "Montserrat-Bold"}}>Contact Us</p>
                  </div>
                </div>

              </div>
              <div style={{height: "100%", minHeight: 450, alignItems: "center", justifyContent: "flex-end", marginRight: width > SECTION_TWO_BREAKPOINT ? "6%" : undefined}}>
                <img style={{width: 300, marginBottom: 0}} src={Screenshot1} />
              </div>
              
            </div>
          </div>
        </div>


        <div style={{ alignItems: "center", marginTop: 25, marginBottom: 100 }}>
        <div style={styles.sectionThree}>
          <div style={styles.sectionThreeRow}>
            <div style={styles.sectionThreeTextContainer}>
              <div style={styles.sectionThreeInnerTextContainer}>
                <h1 style={styles.sectionThreeHeader}>
                  Group chats built for university students
                </h1>
                <div style={styles.sectionThreeDivider} />
                <p style={styles.sectionThreeText}>
                  Class threads are created for all classes and students are automatically added into a class-wide studytable to talk about the latest homework, upcoming exams, and class updates.
                </p>
              </div>
            </div>

            <div style={styles.sectionThreeImgContainer}>
              <img style={{ width: "75%", }} src={DemoThread2} />
            </div>
          </div>

          <div style={styles.sectionThreeRow}>
            {width >= SECTION_THREE_BREAKPOINT ? (
              <div style={styles.sectionThreeImgContainer}>
                <img style={{ width: "75%" }} src={DemoChat} />
              </div>
            ) : null}

            <div style={styles.sectionThreeTextContainer}>
              <div style={styles.sectionThreeInnerTextContainer}>
                <h1 style={styles.sectionThreeHeader}>
                  Direct messages to work with friends, either 1-on-1 or in a group
                </h1>
                <div style={styles.sectionThreeDivider} />
                <p style={styles.sectionThreeText}>
                  When students find classmates they enjoy collaborating with, they can start private chats and group messages in Studytable.
                </p>
              </div>
              {width < SECTION_THREE_BREAKPOINT ? (
                <div style={styles.sectionThreeImgContainer}>
                  <img style={{ width: "75%", marginTop: 50, }} src={DemoChat} />
                </div>
              ) : null}
            </div>
          </div>

          <div style={styles.sectionThreeRow}>
            <div style={styles.sectionThreeTextContainer}>
              <div style={styles.sectionThreeInnerTextContainer}>
                <h1 style={styles.sectionThreeHeader}>
                  Offer a digital planner, to stay organized
                </h1>
                <div style={styles.sectionThreeDivider} />
                <p style={styles.sectionThreeText}>
                  Give students a new way to stay organized. Studytable comes with a digital homework planner designed with features built for college coursework.
                </p>
              </div>
            </div>

            <div style={styles.sectionThreeImgContainer}>
              <img style={{ width: "75%" }} src={DemoPlanner} />
            </div>
          </div>
          <div style={styles.sectionThreeRow}>
            {width >= SECTION_THREE_BREAKPOINT ? (
              <div style={styles.sectionThreeImgContainer}>
                <img style={{ width: "75%" }} src={DemoResources} />
              </div>
            ) : null}

            <div style={styles.sectionThreeTextContainer}>
              <div style={styles.sectionThreeInnerTextContainer}>
                <h1 style={styles.sectionThreeHeader}>
                  Package campus offerings into a resource hub
                </h1>
                <div style={styles.sectionThreeDivider} />
                <p style={styles.sectionThreeText}>
                  Whether it be tutoring, advising, online tools, or other offerings your university provides, package them into a success network directly linked in Studytable.
                </p>
              </div>
              {width < SECTION_THREE_BREAKPOINT ? (
                <div style={styles.sectionThreeImgContainer}>
                  <img style={{ width: "75%", marginTop: 50, }} src={DemoResources} />
                </div>
              ) : null}
            </div>
          </div>
        </div>

        
      </div>

      <div style={{height: 1, width: "100%", backgroundColor: "rgba(255,255,255, 0.1)", boxShadow: "inset 0 0 5px white, 0 0 5px white",}}>

      </div>
      <div style={styles.sectionFour}>
        <h2 style={styles.sectionFourHeader}>
          Want to change the way your institution does student engagement?
        </h2>
        <div onClick={() => {
          window.open(
            "mailto:wes@studytable.com"
          )
        }} style={styles.sectionFourBtn}>
          <p style={styles.sectionFourBtnText}>Contact Us</p>
        </div>
      </div>
      <div style={{height: 1, width: "100%", backgroundColor: "rgba(255,255,255, 0.1)", boxShadow: "inset 0 0 5px white, 0 0 5px white",}}>

</div>

      </div>

      


      <Footer />

      
    </div>
  )

  return (
    <div style={styles.container}>

      <div style={styles.section}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "4%",
            alignItems: "center",
            cursor: "pointer",
            marginTop: 30
          }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <Logo alternate={true} />
        </div>


        <div style={styles.sectionOneBackgroundContainer}>
          <div style={styles.sectionOneImg}/>
          <div style={styles.darkOverlay}/>
        </div>

        <div style={{height: "100%", justifyContent: "center", paddingLeft: "10%"}}>
          <h1 style={{color: "white", fontFamily: "Montserrat-ExtraBold", fontSize: 55, textAlign: "left"}}>
            Create Connected <br/> Classrooms
          </h1>
          <p style={{color: "white", fontFamily: "Montserrat-Medium", opacity: 0.65, width: 550, textAlign: "left", marginTop: 10, marginBottom: 10}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque in ipsum fringilla, tempor quam ut, congue velit.
          </p>
          <div style={{backgroundColor: "#445989", width: 200, height: 50, borderRadius: 10, justifyContent: "center", alignItems: "center", marginTop: 35}}>
            <p style={{color: "white", fontFamily: "Montserrat-Bold"}}>View Demo</p>
          </div>
        </div>
      </div>
      

      <div style={{backgroundColor: "#040A14"}}>

        <div style={styles.section}>

          <div style={{width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
            <div style={{width: "75%", height: 450, backgroundColor: "rgba(255,255,255, 0.07)", boxShadow: "inset 0 0 5px white, 0 0 5px white", borderRadius: 25, border: "1px solid white", flexDirection: "row"}}>
              <div style={{height: "100%", justifyContent: "center", marginLeft: "8%"}}>
                <h1 style={{color: "white", fontFamily: "Montserrat-ExtraBold", fontSize: 45, textAlign: "left"}}>
                  Implement Group <br/> Chats for Every Class
                </h1>
                <p style={{color: "white", fontFamily: "Montserrat-Medium", opacity: 0.65, width: 450, textAlign: "left", marginTop: 10, marginBottom: 10}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque in ipsum fringilla, tempor quam ut, congue velit.
                </p>
                <div style={{backgroundColor: "#445989", width: 200, height: 50, borderRadius: 10, justifyContent: "center", alignItems: "center", marginTop: 35}}>
                  <p style={{color: "white", fontFamily: "Montserrat-Bold"}}>View Demo</p>
                </div>
              </div>
              <div style={{height: "100%", justifyContent: "flex-end", marginLeft: "10%"}}>
                <img style={{width: 300, marginBottom: 0}} src={Screenshot1} />
              </div>
              
            </div>
          </div>
        </div>



        <div style={{ alignItems: "center", marginTop: 100, marginBottom: 100 }}>
        <div style={styles.sectionThree}>
          <div style={styles.sectionThreeRow}>
            <div style={styles.sectionThreeTextContainer}>
              <div style={styles.sectionThreeInnerTextContainer}>
                <h1 style={styles.sectionThreeHeader}>
                  Implement group chats for every class
                </h1>
                <div style={styles.sectionThreeDivider} />
                <p style={styles.sectionThreeText}>
                  Class Threads enters students into a class-wide study table to
                  talk about the latest homework, the upcoming exam, and the
                  latest class updates.
                </p>
              </div>
            </div>

            <div style={styles.sectionThreeImgContainer}>
              <img style={{ width: "75%" }} src={DemoThread2} />
            </div>
          </div>

          <div style={styles.sectionThreeRow}>
            {width >= SECTION_THREE_BREAKPOINT ? (
              <div style={styles.sectionThreeImgContainer}>
                <img style={{ width: "75%" }} src={DemoChat} />
              </div>
            ) : null}

            <div style={styles.sectionThreeTextContainer}>
              <div style={styles.sectionThreeInnerTextContainer}>
                <h1 style={styles.sectionThreeHeader}>
                  Work with friends, 1-on-1 or in a group
                </h1>
                <div style={styles.sectionThreeDivider} />
                <p style={styles.sectionThreeText}>
                  Find people you enjoy collaborating with? Start a private chat
                  with them and add more classmates along the way.
                </p>
              </div>
              {width < SECTION_THREE_BREAKPOINT ? (
                <div style={styles.sectionThreeImgContainer}>
                  <img style={{ width: "75%" }} src={DemoChat} />
                </div>
              ) : null}
            </div>
          </div>

          <div style={styles.sectionThreeRow}>
            <div style={styles.sectionThreeTextContainer}>
              <div style={styles.sectionThreeInnerTextContainer}>
                <h1 style={styles.sectionThreeHeader}>
                  Offer a digital planner, to stay organized
                </h1>
                <div style={styles.sectionThreeDivider} />
                <p style={styles.sectionThreeText}>
                  Studytable comes with a digital homework planner designed with
                  features only a college student needs
                </p>
              </div>
            </div>

            <div style={styles.sectionThreeImgContainer}>
              <img style={{ width: "75%" }} src={DemoPlanner} />
            </div>
          </div>
        </div>
      </div>

      <div style={{height: 1, width: "100%", backgroundColor: "rgba(255,255,255, 0.1)", boxShadow: "inset 0 0 5px white, 0 0 5px white",}}>

      </div>
      <div style={styles.sectionFour}>
        <h2 style={styles.sectionFourHeader}>
          Want to change the way your institution does student engagement?
        </h2>
        <div onClick={() => {
          window.open(
            "mailto:wes@studytable.co?subject=Learn More About Studytable"
          )
        }} style={styles.sectionFourBtn}>
          <p style={styles.sectionFourBtnText}>Contact Us</p>
        </div>
      </div>
      <div style={{height: 1, width: "100%", backgroundColor: "rgba(255,255,255, 0.1)", boxShadow: "inset 0 0 5px white, 0 0 5px white",}}>

</div>

      </div>

      


      <Footer />
    </div>
  );
}

import firebase from 'firebase/compat/app';
import { initializeApp } from 'firebase/app';
import { IS_DEV } from './config/constants';

var firebaseConfig;
if (IS_DEV) {
  firebaseConfig = {
    apiKey: "AIzaSyCGkmcyRL72VZ3U8CPBGB2R-BTxV2ONufw",
    authDomain: "dashboard-dev-8283c.firebaseapp.com",
    projectId: "dashboard-dev-8283c",
    storageBucket: "dashboard-dev-8283c.appspot.com",
    messagingSenderId: "511584381345",
    appId: "1:511584381345:web:5e40496267fad71ee5c3e0",
    measurementId: "G-QLKN34WCQX"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCPD6jzQCn_7Lxjz2suFKsQsoiRMkk_m10",
    authDomain: "dashboard-ad68e.firebaseapp.com",
    databaseURL: "https://dashboard-ad68e-default-rtdb.firebaseio.com",
    projectId: "dashboard-ad68e",
    storageBucket: "dashboard-ad68e.appspot.com",
    messagingSenderId: "19766569800",
    appId: "1:19766569800:web:4768816458e47dbdd7217d",
    measurementId: "G-09BEQ2C8KG"
  };
}

if (!firebase.apps.length) {
  initializeApp(firebaseConfig);
}

export default firebase;
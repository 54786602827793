export default {
    colorOne: "#15233B",
    colorTwo: "#5E73A8",
    greyBlue: "#ACB6CF",
    orange: "#FF8E03",
    orangeTwo: "#EE984D",
    backgroundColor: "#F5F7FA",
    grey: "#A6B5CC",
    mediumGrey: "#D3DBE6",
    lightGrey: "#DFE2E8",
    darkGrey: "#5B5B5B",
    darkWhite: "#F0F2F9",
    red: "#D33C3C",
    navy: "#22355C",
    green: "#239985"
}
import React, { useEffect, useState } from "react";
import colors from "../config/colors";
import { get, ref, query, getDatabase } from "firebase/database";
import { FaUserCheck, FaUser, FaClipboardCheck, FaBookOpen, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import dashboardStyles from "../config/dashboardStyles";

export default function Analytics(props) {
  const db = getDatabase();
  const [eventName, setEventName] = useState();
  const [eventDate, setEventDate] = useState();
  const [events, setEvents] = useState([]);

  
  const getData = async () => {
    try {
      const res = await fetch(
        "https://us-central1-test-bd650.cloudfunctions.net/api/events",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: "93CD2AE795E5EB21185260476D50297E8A90CD87",
            universityId: "studytable-ivytech"
          }),
        }
      );
 
      const data = await res.json();
      console.log(data)
      setEvents(Object.values(data));
    } catch (err) {
      return {};
    }
  };

  useEffect(() => {
    getData()
  }, [])

  const styles = {
    circle: {
      width: 60,
      height: 60,
      borderRadius: 50,
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 25,
      marginTop: 25,
    },
    headerRow: {
      width: 680, 
      flexDirection: "row", 
      alignItems: "center",
      marginTop: 50
    },
    number: {
      fontFamily: "Montserrat-Bold",
      color: colors.colorOne,
    },
    numbersCard: {
      minWidth: 200,
      flex: 1,
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      marginTop: 20,
      justifyContent: "flex-start",
    },
    numbersContainer: {
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
      flexWrap: "wrap",
    },
    numbersDescription: {
      fontFamily: "Montserrat-Medium",
      marginLeft: 30,
      marginRight: 30,
      marginTop: 5,
      color: colors.darkGrey,
      marginBottom: 40,
    },
    numbersHeader: {
      fontFamily: "Montserrat-Bold",
      color: colors.darkGrey,
      marginTop: 10,
    },
    numbersSection: {
      flexDirection: "row",
      flex: 1,
    },
    selectInput: {
      height: 45, 
      width: 300, 
      borderRadius: 10, 
      fontFamily: "Montserrat-Medium", 
      paddingLeft: 5, 
      paddingRight: 5,  
      borderBottom: "0px solid white",  
      borderLeft: "0px solid white",  
      borderTop: "0px solid white", 
      borderRight: "10px solid transparent",  
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)"
    },




    input: {
        color: "#15233b",
        width: "100%",
        height: 45,
        borderRadius: 10,
    },
  };
  
  const save = async (events) => {
    try {
      await fetch(
        "https://us-central1-test-bd650.cloudfunctions.net/api/events",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: "93CD2AE795E5EB21185260476D50297E8A90CD87",
            universityId: "studytable-ivytech",
            events: events
          }),
        }
      );
 
    } catch (err) {
      return {};
    }
  }

  const addEvent = () => {
    var pattern = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/
    if (!eventName) {
      return;
    }

    if (!eventDate || !eventDate.match(pattern)) {
      return;
    }

    const newEvents = [
      ...events,
      {
        name: eventName,
        date: eventDate
      }
    ];
    setEvents(newEvents);
    
    setEventName("");
    setEventDate("");
    save(newEvents);
  }


  const deleteEvent = (item) => {
    const filteredEvents = [];
    for (const event of events) {
      if (event.name != item.name || event.date != item.date) {
        filteredEvents.push(event);
      }
    }

    setEvents(filteredEvents);
    save(filteredEvents);
  }
  
  return (
    <div style={dashboardStyles.container}>
      <div style={{width: 680, maxWidth: "90%",}}>
      <div style={styles.headerRow}>
        <h2 style={{...dashboardStyles.header, marginTop: 0}}>Events</h2>
      </div>

      <div style={{flexDirection: "row", marginTop: 40,  alignItems: "flex-end", marginBottom: 25}}>
        <div style={{width: 200, marginRight: 10}}>
          <small>
          <p style={{fontFamily: "Montserrat-SemiBold", textAlign: "left", marginBottom: 5 }}>Date</p>
          </small>
          <input value={eventDate} onChange={(e) => setEventDate(e.target.value)} placeholder="mm/dd/yyyy" style={styles.input}></input>
        </div>
        <div style={{flex: 1, marginRight: 10}}>
          <small>
          <p style={{fontFamily: "Montserrat-SemiBold", textAlign: "left", marginBottom: 5 }}>Event Name</p>

          </small>
          <input placeholder="e.g. Student Town Hall Meeting" value={eventName} onChange={(e) => setEventName(e.target.value)} style={styles.input}></input>
        </div>
        <div onClick={addEvent} style={{width: 75, height: 45, backgroundColor: colors.orange, borderRadius: 10, justifyContent: "center", alignItems: "center", cursor: "pointer", boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)"}}>
          <small>
            <p style={{fontFamily: "Montserrat-Bold", color: "white"}}>Add</p>
          </small>
        </div>

      </div>

      <div style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)", borderRadius: 10}}>
        <div style={{borderRadius: 10, overflow: "hidden", backgroundColor: "white"}}>

        {events.sort((a, b) => (
 (new Date(a.date)) - (new Date(b.date))
      )
                 
                ).map((item, index) => {
        return (
          <div style={{width: "100%",  height: 45, borderRadius: 20, flexDirection: "row", alignItems: "center", paddingLeft: 15, paddingRight: 15, justifyContent: "space-between"}}>
            <div style={{flexDirection: "row"}}>
              <small style={{width: 90}}>
                <p style={{textAlign: "left", fontFamily: "Montserrat-Medium"}}>{item.date}</p>
              </small>
              <small style={{marginRight: 25, marginLeft :5, fontFamily: "Montserrat-Medium"}}>
                <p>|</p>
              </small>
              <small>
                <p style={{textAlign: "left",fontFamily: "Montserrat-Medium"}}>{item.name}</p>
              </small>
            </div>

            <MdDelete onClick={() => deleteEvent(item)} style={{cursor: "pointer"}} size={20}/>
          </div>
        )
      })}
        </div>
     


      </div>
      




      </div>



      
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./screens/Home.js";
import Dashboard from "./screens/Dashboard.js";
import PrivacyPolicy from "./screens/PrivacyPolicy.js";
import Terms from "./screens/Terms";
import Login from "./screens/Login";
import Links from "./screens/Links";
import Ivytech from "./screens/Ivytech";
import Unsubscribe from "./screens/Unsubscribe";
import firebase from "./Fire";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import getUserData from "./functions/getUserData";

export default function App() {
  const isDashboard = window.location.host.split(".")[0] == "app";

  const auth = getAuth();

  const [uid, setUid] = useState(null);
  const [authReady, setAuthReady] = useState(null);
  const [userData, setUserData] = useState();
  const [studentRegistration, setStudentRegistration] = useState();


  useEffect(() => {
    if (isDashboard) {
      onAuthStateChanged(auth, async (user) => {
        await verifyUser(user);
      });
    }
  }, []);

    // authentication functions =======================
    const logout = async () => {
      signOut(auth)
        .then(() => {
          // Sign-out successful.
        })
        .catch((error) => {
          // An error happened.
        });
    };
  
    const verifyUser = async (user) => {
      if (user) {
        const userData = await getUserData();

        if (true) {
          setUserData(userData);
  
          setUid(user.uid);
          setAuthReady(true);
        } else {
          setUserData(null);
          setUid(null);
          setAuthReady(true);
        }

      } else {
        setUserData(null);
        setUid(null);
        setAuthReady(true);
      }
    };
    // =============================================

  const LandingPage = () => {
    return (
      <Router>
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/app" element={<Links />} />
            <Route path="/ivytech" element={<Ivytech />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
          </Routes>
        </div>
      </Router>
    );
  };
  
  const AdminDashboard = () => {
    return (
      <Router>
        <div className="container">
          <Routes>
            {!authReady ? <Route path="/" element={null} /> : []}
            {authReady && uid ? <Route path="/" element={<Dashboard logout={logout} userData={userData} />} /> : []}
            {authReady && !uid ? <Route path="/" element={<Login />} /> : []}
          </Routes>
        </div>
      </Router>
    );
  };

  return (
    <div className="App">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {isDashboard ? 
        <AdminDashboard />
      :
        <LandingPage />
      }
    </div>
  );
}

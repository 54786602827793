import React from "react";
import useWindowDimensions from "../functions/useWindowDimensions";
import Navbar from "../components/Navbar";
import TermsBackground from "../assets/images/TermsBackground.png";
import Footer from "../components/Footer";

export default function Terms() {
  const { height, width } = useWindowDimensions();

  let styles = {
    bold: {
      fontFamily: "Montserrat-Bold",
    },
    divider: {
      marginTop: 50,
      height: 1,
      width: "100%",
      backgroundColor: "#DFE2E8",
    },
    paragraph: {
      textAlign: "left",
      marginTop: 25,
      color: "#15233B",
      fontFamily: "Montserrat-Regular",
      textAlign: "justify",
    },
    header: {
      fontFamily: "Montserrat-Bold",
      marginTop: 50,
      color: "#15233B",
      textAlign: "left",
    },
    subheader: {
        fontFamily: "Montserrat-Bold",
        marginTop: 50,
        color: "#15233B",
        textAlign: "left",
    }
  };

  return (
    <div>
      <Navbar />

      <div
        style={{
          background: `url(${TermsBackground})`,
          height: 300,
          backgroundSize: "cover",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            zIndex: 1,
            fontFamily: "Montserrat-Bold",
            color: "white",
            fontSize: 45,
            maxWidth: "90%"
          }}
        >
          Terms of Service
        </h1>
      </div>
      <div style={{ width: "100%", alignItems: "center" }}>
        <div style={{ width: 700, maxWidth: "80%", paddingTop: 25 }}>
       
          <p
            style={styles.paragraph}
          >
            These Terms of Service ("Agreement") are entered into by and between
            Tudr Media, LLC, and Indiana Limited Liability Company (The
            "Company") and You ("You" or "User"). By using our services (as
            defined below) as a User, You agree to be bound by and acknowledge
            Your complete acceptance of this Agreement. In no event may you
            access, receive or otherwise use the Studytable services without
            agreeing to this Agreement. This Agreement is effective as of May
            31, 2022.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>1. Definitions</h1>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"Academic Term"</span> means the academic
            term during which a Course will be offered (e.g., fall, winter,
            spring, and/or summer).
          </p>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"Class Thread"</span> is the mechanism in
            which enables Students to correspond with one another about
            coursework, examinations, etc...
          </p>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"Course"</span> means an academic course
            developed by the Institution with a set of assignments and
            activities that is designed to achieve a particular set of learning
            objectives within a particular Academic Term.
          </p>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"Course Content"</span> means the
            descriptive features of the Courses, including, without limitation,
            course descriptions, proposed learning objectives and course
            subjects, that comprise of an area of specialization in addition to
            units of learning, typically defined by anticipated outcomes,
            including, without limitation, syllabi, assignments, notes,
            presentations and examinations.
          </p>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"Institution"</span> means an academic
            institution, including, without limitation, colleges and
            universities, whether public or private.
          </p>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"Instructor"</span> means an instructor of
            the Institution given access to the Platform by the Institution for
            the administration and management of a Course
          </p>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"Login"</span> means the unique
            combination of username and password, SMS verification, or
            verification through a third party service, designed to provide
            access for a single individual or business to the Service.
          </p>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"Platform"</span> means the Company hosted
            software-as-a-service platform, including Class Threads and other
            functionality included therein.
          </p>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"Service"</span> means the services and
            functionality provided by the Platform and any other services
            provided by the Company to the Institution from time to time.
          </p>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"Student"</span> means a student enrolled
            at the Institution given access to the Platform by the Institution
            for a particular Course.
          </p>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"Third Party Services"</span> mean any
            applications, products, and services not provided by the Company
            that are used by User in combination with the Service.
          </p>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"User Material"</span> means any data,
            information, or other material supplied by or on behalf of User and
            submitted through the Service by User or over the course of using
            the Service, including, without limitation Course Content.
          </p>
          <p style={styles.paragraph}>
            <span style={styles.bold}>"Confidential Information"</span> means,
            without limitation, any proprietary information, customer and end
            user data, software, product plans, inventions, technical data,
            trade secrets, know-how, processes, the existence and terms of this
            Agreement, or other business or financial information, in each case
            disclosed or made available by a party (the “Disclosing Party”) to
            the other party (the “Receiving Party”) whether orally or in writing
            hereunder.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>2. Nature of the Service</h1>
          <p style={styles.paragraph}>
            Subject to the terms and conditions of this Agreement, the Company
            grants to You a non-sublicensable, non-transferable, non-exclusive
            subscription to access and use the Platform during the applicable
            Academic Term. The Company may, at its sole discretion, change,
            remove, add, or enhance features of the Platform from time to time.
          </p>
          <p style={styles.paragraph}>
            The Platform is an online platform which enables Students and their
            Instructors to collaborate and upload User Material during an
            Academic Term. To the extent that a User requests the Company's
            assistance to use the Platform, the Company consents to the actions
            that the Company performs on its behalf. The User retains sole
            responsibility for such assisted use of the Platform, provided that
            the Company's assistance is reasonably within the scope of the
            User's request.
          </p>
          <p style={styles.paragraph}>
            The Institution is responsible for ensuring that all Students
            enrolled in a particular Course have obtained access to the
            Platform.
          </p>
          <p style={styles.paragraph}>
            Students understand and acknowledge that the Company permits
            Students to upload Course Content to facilitate the education and
            learning process. Notwithstanding the foregoing, Institutions may
            have their own policies that place restrictions on a Student's
            ability to use and distribute certain study materials and to discuss
            the contents thereof with other Students. Students are responsible
            for becoming familiar with such policies, abiding by them, and
            discontinuing the use of any Services if such use is contrary to
            their Institution's policies or the instruction of their Instructor.
            Course Content is solely for the specific use of Students who are in
            the designated Course for which they are created. Individuals who
            are not registered for the Course may not use or distribute the
            applicable Course Content.
          </p>
          <p style={styles.paragraph}>
            The Company is not responsible for any materials provided by Users
            or exchanges made by Users. In creating study related content, Users
            must respect any intellectual property rights that others may have
            in such content. Furthermore, the Company does not hire or employ
            Instructors. As such, the Company does not guarantee the accuracy or
            completeness of any Course Content. The Company also has no control
            over the reliability, competence, skillfulness, qualifications, or
            any of the acts or omissions of Instructors or Students. The Company
            makes no representations or warranties about the results,
            dependability, timeliness, or accuracy of the Course Content and the
            exchanges of or between Users identified through the Service in any
            manner. USER HEREBY UNDERSTANDS AND ACKNOWLEDGES THAT THE COMPANY
            DOES NOT CONTROL, DIRECT, SUPERVISE OR MONITOR A USER'S ACTIONS AND
            EXPRESSLY DISCLAIMS, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
            LAW, ANY OBLIGATION AND LIABILITY FOR A USER'S ACTIONS OR OMISSIONS
            AND THE COURSE CONTENT IN ANY MANNER, INCLUDING, WITHOUT LIMITATION,
            A WARRANTY OR CONDITION OF SERVICES PERFORMED IN A GOOD AND
            WORKMANLIKE MANNER, WARRANTY OR CONDITION OF QUALITY OR FITNESS FOR
            A PARTICULAR PURPOSE, OR COMPLIANCE WITH ANY LAW.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>3. Account Security</h1>
          <p style={styles.paragraph}>
            Each User accessing the Platform must have a unique Login. Users
            shall not permit or authorize anyone other than such User to use
            their Login. The Company shall have the discretion to remove a
            User's Login when such User will no longer be using the Platform.
            User shall immediately notify the Company in the event User becomes
            aware of any authorized use of a Login.
          </p>
          <h2 style={styles.subheader}>Platform Restrictions</h2>
          <p style={styles.paragraph}>
            User shall not itself, or through any third party: (i) install,
            configure, access, use, copy or replicate all or any portion of the
            Platform; (ii) modify, reverse engineer, decompile, disassemble,
            distribute, create derivative works based on, copy or otherwise
            exploit all or any portion of the Platform, except as expressly
            permitted by applicable law; (iii) sell, sublicense, rent, lease, or
            otherwise transfer rights to all or any portion of the Platform;
            (iv) use the Platform to operate in or as a time-sharing,
            outsourcing or service bureau environment or in any manner which
            supports the business of a third party; (v) obscure, remove or alter
            any intellectual property rights notices or markings on the
            Platform; (vi) access the Platform for purposes of creating or
            promoting a competitive product; (vii) use the Platform to store or
            transmit a virus or malicious code; or (viii) use the Platform in a
            manner inconsistent with any applicable law, rule or regulation.
          </p>
          <h2 style={styles.subheader}>User Responsibilities</h2>
          <p style={styles.paragraph}>
            Each User accessing the Platform must have a unique Login. Users
            shall not permit or authorize anyone other than such User to use
            their Login. The Company shall have the discretion to remove a
            User's Login when such User will no longer be using the Platform.
            User shall immediately notify the Company in the event User becomes
            aware of any authorized use of a Login.
          </p>
          <h2 style={styles.subheader}>Acceptable Use Policy</h2>
          <p style={styles.paragraph}>
            When You contribute, upload, or otherwise distribute User Material
            through the Platform, You agree to comply with the following
            Acceptable Use Policy. In addition to removing such prohibited
            materials, the Company may terminate the responsible accounts,
            and/or report such activities to law enforcement authorities as
            appropriate. Prohibited user content includes, without limitation,
            material that the Company (in its sole discretion) finds: (i) is
            patently offensive or promotes or otherwise encourages racism,
            bigotry, hatred, or harm of any kind to any group or individual;
            (ii) harasses someone else; (iii) exploits another in a sexual or
            violent manner or contains nudity, excessive violence, or offensive
            subject matter or contains a link to an adult or otherwise
            objectionable website; (iv) constitutes a potential or actual
            privacy or security risk; (v) is or encourages information that You
            know is false or misleading or promotes unlawful activities or
            conduct that is abusive, threatening, obscene, defamatory or
            libelous; (vi) is or encourages an unlawful or unauthorized copy of
            another's copyrighted work; (vii) involves unsolicited mass mailing
            or “spamming” of any kind; (viii) solicits or is intended to solicit
            passwords or personal identifying information for commercial or
            unlawful purposes from other Users; and (ix) is a violation of or
            attempts to violate the privacy rights, publicity rights,
            intellectual property rights, contract rights or any other rights of
            any individual or entity whatsoever.
          </p>
          <h2 style={styles.subheader}>Third Party Services</h2>
          <p style={styles.paragraph}>
            Certain third-party solutions and services offered by one or more
            non-affiliate third parties may be integrated into the Service or
            delivered separately. Additional terms may apply to User's use of a
            Third Party Service. In the event additional terms do apply, such
            terms are set forth between User and the Third Party Services
            provider. User acknowledges and agrees that: (i) User acquires no
            rights, title or interest in or to any intellectual property rights
            underlying a Third Party Service, except for the express rights
            granted to User under this Agreement; (ii) THE COMPANY MAKES NO
            REPRESENTATIONS, WARRANTIES, COVENANTS OR SERVICE LEVEL COMMITMENTS
            OF ANY KIND REGARDING ANY THIRD PARTY SERVICE, AND (iii) THE COMPANY
            OFFERS AND ASSUMES NO INDEMNITY OBLIGATIONS OR OTHER LIABILITIES OF
            ANY KIND REGARDING EACH THIRD PARTY SERVICE.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>4. Privacy</h1>
          <p style={styles.paragraph}>
            The use of any personal data You submit to the Platform or which we
            collect about You is governed by our Privacy Policy . You
            acknowledge that by using the Platform You have reviewed the Privacy
            Policy. Access the Privacy Policy via <a href="https://studytable.co/privacy">https://studytable.co/privacy</a>
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>
            5. Representation and Warranty Disclaimers
          </h1>
          <h2 style={styles.subheader}>Mutual Warranty</h2>
          <p style={styles.paragraph}>
            Each party represents and warrants that it has the legal power and
            authority to enter into this Agreement.
          </p>
          <h2 style={styles.subheader}>User Warranty</h2>
          <p style={styles.paragraph}>
            User represents and warrants that it: (i) has read, understands, and
            agrees to be bound by this Agreement; (ii) owns the User Material
            submitted by User on, through or in connection with the Services, or
            otherwise has the right to grant the licenses set forth in this
            section; (iii) the posting of its User Material on, through or in
            connection with the Services does not violate the privacy rights,
            publicity rights, copyrights, contract rights or any other rights of
            any person or entity; and (iv) shall comply with all federal, state
            and local laws, regulations, ordinances and rules that are
            applicable to its activities and obligations under this Agreement.
            Upon the Company's request, User will furnish the Company any
            documentation, substantiation or releases necessary to verify its
            compliance with this Agreement.
          </p>
          <h2 style={styles.subheader}>Disclaimer of Warranties</h2>
          <p style={styles.paragraph}>
            EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, USER UNDERSTANDS
            AND AGREES THAT THE SERVICE, TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, IS PROVIDED “AS IS” AND THE COMPANY EXPRESSLY
            DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED,
            STATUTORY OR OTHERWISE, INCLUDING WITHOUT LIMITATION, ANY WARRANTY
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT. THE COMPANY MAKES NO REPRESENTATION, WARRANTY, OR
            GUARANTY THAT THE SERVICE WILL OPERATE IN COMBINATION WITH USER'S
            HARDWARE, OTHER SOFTWARE, THIRD PARTY SERVICES, OR USER MATERIAL.
            THE COMPANY FURTHER MAKES NO REPRESENTATION, WARRANTY, OR
            GUARANTYAND HAS NO LIABILITY REGARDING ANY USER ACTION ON THE
            PLATFORM OR USER MATERIAL. The Company makes reasonable efforts to
            ensure the Service is free of viruses or other harmful components
            but cannot guarantee that the Service will be free from unknown
            viruses or harmful components. The Company cannot guarantee that the
            Service will not incur delays, interruptions, or other errors that
            are outside of the Company's reasonable control and are inherent
            with the use of the internet and electronic communications.
          </p>
          <div style={styles.divider} />
          <h2 style={styles.subheader}>
            6. Ownership of Intellectual Property Rights
          </h2>
          <p style={styles.paragraph}>
            The parties do not intend to convey under this Agreement, any
            intellectual property rights in, or associated with, any of their
            respective products, services or work product, including but not
            limited to course design, instructional content and student work
            product. Accordingly, except as expressly set forth herein, each
            party shall retain ownership of its own products, services and
            intellectual property utilized or generated by such party in
            connection with performance of this Agreement and nothing in this
            Agreement shall be construed to grant the other party any right,
            title or license therein.
          </p>
          <p style={styles.paragraph}>
            Except as expressly set forth in this Agreement, all intellectual
            property rights in and to the Service, the Platform and all other
            materials the Company provides to User for use in connection with
            this Agreement will at all times remain the sole property of the
            Company and its licensors. The Company owns all content, data,
            software, inventions, ideas, and other technology and intellectual
            property that it develops in connection with its products, the
            Service and the Platform.
          </p>
          <p style={styles.paragraph}>
            The User (as applicable, or its licensors) retains all right, title
            and interest in the User Material. The User grants to the Company
            all necessary licenses and rights in and to the User Material solely
            as necessary for the Company to provide the Service and Platform to
            the User or as required by applicable law. The Company will not
            knowingly use or access any User Material except as necessary to
            provide the Service and the Platform. The User shall be solely
            responsible for the User Material it posts on the Platform or
            otherwise makes available through the Service, and the User
            acknowledges that any reliance on any content posted by Instructors
            and Students will be at the User's own risk. Notwithstanding the
            foregoing, the Company reserves the right to refuse to accept, post,
            display or transmit any User Material in its sole discretion.
          </p>
          <h2 style={styles.subheader}>
            Reporting Copyright and Other Intellectual Property Violations
          </h2>
          <p style={styles.paragraph}>
            The Company respects the intellectual property rights of others and
            asks that You do the same. If You believe that Your work has been
            infringed, please provide us with the following information: (i) An
            electronic or physical signature of the person authorized to act on
            behalf of the owner of the copyright interest; (ii) A description of
            the copyrighted work that You claim has been infringed; (iii) A
            description of where the material that You claim is infringing is
            located on the Platform or the Service; (iv) Your address, telephone
            number, and email address; (v) A statement by You that You have a
            good faith belief that the disputed use is not authorized by the
            copyright owner, its agent, or the law; and (vi) A statement by You,
            made under penalty of perjury, that the above information in Your
            notice is accurate and that You are the copyright owner or
            authorized to act on the copyright owner's behalf.
          </p>
          <p style={styles.paragraph}>
            For notice of claims of copyright infringement on the Platform or
            the Service, please email the Company at <a href={"mailto: support@studytable.co"} >support@studytable.co</a>.
            Please note that any misrepresentation of material fact (falsities)
            in a written notification automatically subjects the complaining
            party to liability for any damages, costs, and attorneys' fees
            incurred by the Company in connection with the written notification
            and allegation of copyright infringement.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>7. Confidentiality</h1>
          <p style={styles.paragraph}>
            The Receiving Party shall: (i) keep all Confidential Information
            confidential and secure during and after this Agreement, using at
            least the same degree of care that the Receiving Party uses to
            protect its own most confidential information, but in no event less
            than reasonable care; (ii) use Confidential Information solely as
            and to the extent required for the performance of the Service during
            the Term; (iii) not disclose or divulge the same to any third party
            without the express prior written consent of the Disclosing Party in
            each instance; and (iv) notify the Disclosing Party immediately in
            the event the Receiving Party becomes aware of any loss or
            disclosure of any Confidential Information. Notwithstanding the
            foregoing, the Receiving Party may disclose Confidential Information
            solely to the extent necessary to comply with a court order or as
            otherwise required by law or a government body, provided that the
            Receiving Party must give the Disclosing Party prompt written notice
            and obtain or allow for a reasonable effort by the Disclosing Party
            to obtain a protective order prior to disclosure.
          </p>
          <p style={styles.paragraph}>
            Confidential Information shall not include information that: (i) was
            already known or in the Receiving Party's possession, without
            restriction, at the time of disclosure, as demonstrated by
            documentation or other competent evidence in existence at the time
            of disclosure; (ii) was already known by the Receiving Party,
            without restriction, at the time of disclosure, having been
            disclosed to the Receiving Party by a third party without such third
            party having a confidentiality obligation with respect to such
            information; (iii) is or becomes publicly known through no wrongful
            act by the Receiving Party; or (iv) was independently developed by
            the Receiving Party without reference or access to any Confidential
            Information, as demonstrated by documentation or other competent
            evidence created at the time of such independent development.
          </p>
          <p style={styles.paragraph}>
            The Receiving Party will return to the Disclosing Party or use
            reasonable efforts to destroy all Confidential Information of the
            Disclosing Party in the Receiving Party's possession or control
            promptly upon the expiration or termination of this Agreement. Upon
            the Disclosing Party's request, the Receiving Party will certify in
            writing that it has fully complied with its obligations under this
            Section (Confidentiality).
          </p>
          <p style={styles.paragraph}>
            It is agreed that money damages would not be a sufficient remedy for
            any breach by the Receiving Party of this Section 7, and the
            Disclosing Party will be entitled to injunctive relief, specific
            performance, and/or other appropriate equitable remedy for any such
            breach. The Disclosing Party's election to pursue injunctive relief
            shall not be a waiver of any of its other remedies available to it
            under law, equitable principles, or other legal theories.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>8. Indemnification</h1>
          <p style={styles.paragraph}>
            The Company shall defend, indemnify and hold User harmless from any
            damages, attorneys' fees, finally awarded judgments or settlements
            arising from any claims, demands, suits or proceedings made or
            brought by a third party against User, in each case to the extent
            arising from any allegation that the Platform infringes any patent,
            copyright, or trade secret of a third party. Notwithstanding the
            foregoing, the Company will have no liability for any infringement
            claim of any kind if the claim results from: (i) modification of the
            Service or Platform made by User or its agents; (ii) unauthorized or
            unlicensed use of the Service or Platform; or (iii) User Material.
            The Company shall have no obligation to indemnify or defend User to
            the extent any claim arises from User's use of any Third Party
            Services.
          </p>
          <p style={styles.paragraph}>
            The User shall defend, indemnify and hold the Company harmless from
            any damages, attorneys' fees, finally awarded judgments or
            settlements arising from any claims, demands, suits or proceedings
            made or brought by a third party against the Company, in each case
            to the extent arising from or related to: (i) any breach of any
            representation, warranty, covenant or other obligation in this
            Agreement by the User; (ii) any allegation that User Material
            infringes the intellectual property rights or any other right of a
            third party; (iii) the User's breach of its confidentiality
            obligations under Section 7 hereof; or (iv) gross negligence or
            willful misconduct by the User.
          </p>
          <p style={styles.paragraph}>
            If the Company receives information about an infringement claim
            related to the Service or the Platform, the Company shall in its
            sole discretion: (i) modify the Service or the Platform so that it
            no longer infringes; (ii) obtain a license for the User's continued
            use of the Service or the Platform in accordance with this
            Agreement; or (iii) if (i) and (ii) are not reasonably practicable,
            then terminate the User's subscription for the Service.
          </p>
          <p style={styles.paragraph}>
            The party seeking indemnification must: (i) promptly notify the
            indemnifying party in writing of the applicable claim for which
            indemnification is sought; provided, that failure to notify shall
            not relieve a party of its indemnification obligations unless the
            indemnifying party has been materially prejudiced thereby; (ii) give
            the indemnifying party sole control of the defense and settlement of
            the claim (except that the indemnifying party may not settle a claim
            unless it unconditionally releases the indemnified party of all
            liability); and (iii) provide the indemnifying party with all
            non-monetary assistance, information and authority reasonably
            required for the defense and settlement of such claim.
          </p>
          <p style={styles.paragraph}>
            This Section 8 (Indemnification) states the Company's sole and
            exclusive obligations, and User's sole and exclusive remedies, with
            respect to infringement of any intellectual property rights of any
            kind.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>9. Limitation of Liability</h1>
          <p style={styles.paragraph}>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE COMPANY'S MAXIMUM
            LIABILITY ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, THE
            PLATFORM, THE SERVICE OR YOUR USE OF ANY USER MATERIAL, REGARDLESS
            OF THE CAUSE OF ACTION (WHETHER IN CONTRACT, TORT, BREACH OF
            WARRANTY OR OTHERWISE) WILL NOT EXCEED THE GREATER OF: (I) THE
            AMOUNTS PAID BY YOU, IF ANY, TO THE COMPANY IN THE PAST SIX (6)
            MONTHS; OR (II) TWO HUNDRED FIFTY DOLLARS ($250). THE ABOVE
            LIMITATIONS WILL APPLY WHETHER AN ACTION IS IN CONTRACT OR TORT AND
            REGARDLESS OF THE THEORY OF LIABILITY.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>10. Term and Termination</h1>
          <p style={styles.paragraph}>
            This Agreement will remain in full force and effect while You are a
            User of the Platform. You may terminate this Agreement at any time
            by ceasing all use of the Platform and deactivating Your account. At
            the end of an Academic Term, Students that are deactivated will be
            removed from the Platform.
          </p>
          <p style={styles.paragraph}>
            The Company reserves the right, at its sole discretion, to pursue
            all of its legal remedies, including but not limited to removal of
            User Material from the Platform and immediate termination of User's
            account without ability to access the Platform, the Service and/or
            any other services provided to You by the Company, upon any breach
            by You of this Agreement. Even after Your right to use the Platform
            is suspended, terminated or limited, this Agreement will remain
            enforceable against You.
          </p>
          <p style={styles.paragraph}>
            All parts of this Agreement which by their nature should survive the
            expiration or termination of this Agreement shall continue in full
            force and effect subsequent to and notwithstanding the expiration or
            termination of this Agreement or Your use of the Service.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>11. Miscellaneous</h1>
          <p style={styles.paragraph}>
            This Agreement does not establish any relationship of partnership,
            franchise, joint venture, employment, fiduciary, or agency between
            the parties. Neither party will have the authority to bind the other
            party or incur obligations on the other's behalf without the other
            party's prior written consent. Except where specifically stated, the
            Company has no affiliation with nor endorsement from any
            Institution, and school names and information are used on the
            Platform only to the extent necessary to organize, identify and
            permit use of information and sections of the Platform that may be
            tailored to Students of a particular Institution, or for other
            lawful uses.
          </p>
          <p style={styles.paragraph}>
            All notices under this Agreement shall be in writing and delivered
            by courier or overnight delivery service, certified mail, or
            electronic mail and in each instance will be deemed given upon
            receipt. Notices of termination or of an indemnifiable claim shall
            be identified as “Legal Notices”. Notices to the Company shall be
            sent to <a href={"mailto: support@studytable.co"} >support@studytable.co</a> with email subject (in English) “Legal
            Notice”. All notices to User will be sent to the physical address
            and/or email addresses set forth in the User's account.
          </p>
          <p style={styles.paragraph}>
            Headings used in this Agreement are provided for convenience only
            and will not in any way affect the meaning or interpretation of this
            Agreement or any portion thereof. Any term or provision of this
            Agreement which is invalid or unenforceable in any jurisdiction
            shall, as to that jurisdiction, be ineffective to the extent of such
            invalidity or unenforceability without rendering invalid or
            unenforceable the remaining terms and provisions of this Agreement
            or affecting the validity or enforceability of any of the terms or
            provisions of this Agreement in any other jurisdiction.
          </p>
          <p style={styles.paragraph}>
            No waiver by any party of any term or condition of this Agreement,
            in any one or more instances, shall be deemed to be or construed as
            a waiver of the same or any other term or condition of this
            Agreement on any future occasion. All remedies, either under this
            Agreement or by law or otherwise afforded, will be cumulative and
            not alternative.
          </p>
          <div style={styles.divider} />
          <h2 style={styles.subheader}>Waiver and Cumulative Remedies</h2>
          <p style={styles.paragraph}>
            Neither party may assign this Agreement or any of its right or
            obligations under this Agreement without the other party's prior
            written consent, which consent shall not be unreasonably withheld.
            Notwithstanding the foregoing, either party may transfer and/or
            assign this Agreement, without the other party's consent, to any
            successor by way of a merger, acquisition, or change of control. Any
            attempt by a party to assign its rights or obligations under this
            Agreement in breach of this Section (Waiver and Cumulative Remedies
            shall be void and of no effect. Subject to the foregoing, this
            Agreement shall bind and inure to the benefit of the parties, their
            respective successors and permitted assigns.
          </p>
          <p style={styles.paragraph}>
            This Agreement shall be governed by and construed in accordance with
            the laws of the State of Indiana, without regard to its conflict of
            laws rules. The state and federal courts located in the State of
            Indiana shall have exclusive jurisdiction to adjudicate any dispute
            arising of or relating to this Agreement. Each party hereby consents
            to the exclusive jurisdiction of such courts. EACH PARTY ALSO WAIVES
            ANY RIGHT TO TRIAL BY JURY IN CONNECTION WITH ANY ACTION OR
            LITIGATION IN ANY WAY ARISING OUT OF OR RELATED TO THIS AGREEMENT.
          </p>
          <p style={styles.paragraph}>
            A party shall not be considered to be in default or breach of this
            Agreement, and shall be excused from performance or liability for
            damages, as a result of any delay, failure in performance, or
            interruption of service resulting directly or indirectly from acts
            of God, network failures, acts of civil or military authorities,
            civil disturbances, wars, terrorism, energy crises, fires,
            transportation contingencies, interruptions in third party
            telecommunications or Internet equipment or service, other
            catastrophes, or any other occurrences which are beyond such party's
            reasonable control (each, a “Force Majeure Event”). This Section
            12.7 does not excuse User's payment of fees due under this
            Agreement.
          </p>
          <p style={styles.paragraph}>
            This Agreement constitutes the entire agreement between the parties
            regarding User's use of the Service and supersedes all prior and
            contemporaneous agreements, proposals or representations, written or
            oral, concerning its subject matter. No modification or amendment of
            any provision of this Agreement shall be effective unless in writing
            signed by the parties. This Agreement is binding upon and inures to
            the benefit of, the parties and their respective permitted
            successors and assigns.
          </p>
        </div>
      </div>
      <div style={{marginTop: 100}} />
      <Footer />
    </div>
  );
}

import React, { useEffect } from "react";
import useWindowDimensions from "../functions/useWindowDimensions";
import Navbar from "../components/Navbar";
import colors from "../config/colors";
import { useSearchParams } from "react-router-dom";
import { ref, push, getDatabase } from "firebase/database";

export default function Unsubscribe() {
  const { height, width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const db = getDatabase();

  useEffect(() => {
    const email = searchParams.get("email");

    if (email) {
        const unsubscribeRef = ref(db, "unsubscribed/");
        push(unsubscribeRef, email);
    }
  }, [])

  let styles = {
    header: {
      fontFamily: "Montserrat-Bold",
      marginTop: 50,
      color: colors.colorOne,
      textAlign: "center",
    },
    subHeader: {
        fontFamily: "Montserrat-Medium",
        color: colors.colorOne,
        textAlign: "center",
        marginTop: 10
    },
  };

  return (
    <div style={{ height: height }}>
      <Navbar alternate={true} />
      <div style={{ alignItems: "center" }}>
        <h1 style={styles.header}>Success</h1>
        <h4 style={styles.subHeader}>You have been unsubscribed.</h4>
      </div>
    </div>
  );
}

import { get, ref, query, getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getEmailEnding, getUsername } from "./emailHelpers";

export default async function getUserData() {
  const db = getDatabase();
  const auth = getAuth();

  const email = auth.currentUser.email;
  const emailEnding = getEmailEnding(email);
  const username = getUsername(email);

  const userDataRef = ref(db, "userData/" + emailEnding + "/" + username);
  const userDataSnapshot = await get(query(userDataRef));

  return userDataSnapshot.val();
}

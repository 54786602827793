import React from "react";
import useWindowDimensions from "../functions/useWindowDimensions";
import Navbar from "../components/Navbar";
import TermsBackground from "../assets/images/TermsBackground.png";
import Footer from "../components/Footer";

export default function PrivacyPolicy() {
  const { height, width } = useWindowDimensions();

  let styles = {
    bold: {
      fontFamily: "Montserrat-Bold",
    },
    bulletpoint: {
      textAlign: "left",
      color: "#15233B",
      fontFamily: "Montserrat-Regular",
      textAlign: "justify",
      marginTop: 10,
    },
    divider: {
      marginTop: 50,
      height: 1,
      width: "100%",
      backgroundColor: "#DFE2E8",
    },
    paragraph: {
      textAlign: "left",
      marginTop: 25,
      color: "#15233B",
      fontFamily: "Montserrat-Regular",
      textAlign: "justify",
    },
    header: {
      fontFamily: "Montserrat-Bold",
      marginTop: 50,
      color: "#15233B",
      textAlign: "left",
    },
    subheader: {
      fontFamily: "Montserrat-Bold",
      marginTop: 50,
      color: "#15233B",
      textAlign: "left",
    },
  };

  return (
    <div>
      <Navbar />

      <div
        style={{
          background: `url(${TermsBackground})`,
          height: 300,
          backgroundSize: "cover",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            zIndex: 1,
            fontFamily: "Montserrat-Bold",
            color: "white",
            fontSize: 45,
            maxWidth: "90%",
          }}
        >
          Privacy Policy
        </h1>
      </div>
      <div style={{ width: "100%", alignItems: "center" }}>
        <div style={{ width: 700, maxWidth: "80%", paddingTop: 25 }}>
          <p style={styles.paragraph}>
            Tudr Media, LLC, a corporation organized under the laws of Indiana
            (“Company”, “we”, or “us”), is committed to protecting your privacy
            and maintaining your trust. This Privacy Policy explains how we
            collect, use, disclose and protect your information when you use our
            Service. We also provide information on privacy rights, how to
            exercise these rights and how to contact us with any questions.
            Capitalized terms that are not defined in this Privacy Policy shall
            have the meanings ascribed to them in our Terms and Conditions (the
            “Agreement”). This Agreement is effective as of May 31, 2022
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>1. Acknowledgement</h1>
          <p style={{ ...styles.paragraph, ...styles.bold }}>
            By using the Service, you consent to the data practices described in
            this Privacy Policy. If you do not want your information processed
            in accordance with this Privacy Policy in general, or any part of
            it, you should not use our Service.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>
            2. Personal Information We Collect and Why
          </h1>
          <p style={styles.paragraph}>
            “Personal Information” is any information that can identify or is
            linked to a specific individual, including, without limitation, your
            name, mailing address, e-mail address, telephone number, credit card
            number, debit card number, bank account number, demographic,
            location and household information. We collect the following types
            of Personal Information about you:
          </p>
          <h2 style={styles.subheader}>
            Personal Information you provide us directly
          </h2>
          <p style={styles.paragraph}>
            We may request certain Personal Information when you register for a
            Company account or when you contact us directly, such as a Login,
            your first and last name, academic history, mailing address and
            e-mail address.
          </p>
          <p style={styles.paragraph}>
            We also collect any feedback you send us and may collect Personal
            Information you provide in User Material you post to the Platform,
            such as text and photos you upload to the Platform. We use this
            Personal Information to operate, maintain, and provide the features
            and functionality of the Service to you, to communicate with you,
            and to address any concerns you may have about the Service.
          </p>
          <p style={styles.paragraph}>
            You are not required to provide this information. Please be aware,
            however, that if you do not provide the Personal Information
            requested, you may not be able to access or use our Service.
          </p>
          <h2 style={styles.subheader}>
            Personal Information we collect from you automatically
          </h2>
          <p style={styles.paragraph}>
            We may collect certain Personal Information automatically when you
            use the Service, such as your IP address, type of browser, the date
            and time you visit, information regarding the links you click on our
            Service, the content you view, how you navigate the Service, and
            other customary server log information used to help us measure
            traffic and usage trends for the Service. We may also collect
            certain Personal Information to understand where you are located in
            order to provide you with personalized content and undertake
            aggregated data analytics. We may collect your precise or
            approximate location: (i) from you when you directly provide or
            confirm you location with us; (ii) by inferring the location from
            your IP address; and (iii) from our third-party service providers.
          </p>
          <h2 style={styles.subheader}>
            Personal Information derived from cookies and taken from other
            similar technologies
          </h2>
          <p style={styles.paragraph}>
            When you use our Service, we and our third-party service providers
            will send cookies - small data files that are placed on your
            computer or mobile device when you visit the Service - and other
            similar technologies to your computer that uniquely identifies your
            browser. These cookies allow us to help you log in to the Service
            faster, enhance your navigation of our website, remember your
            preferences and generally improves the content and offerings across
            our Service. They also provide us with information about how you use
            the Service (e.g., the pages you are on, the content you view, and
            the links you click) and allow us and our third-party service
            providers to track your usage of the Service.
          </p>
          <p style={styles.paragraph}>
            You can control or reset your cookies and similar technologies
            through your web browser, which will allow you to customize your
            cookie preferences and to refuse all cookies or to indicate when a
            cookie is being sent. However, certain features of the Service may
            not function properly if these cookies are refused, disabled, or
            deleted. For more information on how we use cookies and other
            similar technologies and how you can control them, please read
            Section 5 (Cookies) below.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>
            3. How Your Personal Information is Used
          </h1>
          <p style={styles.paragraph}>
            In addition to the purposes of collection described above, we use
            the Personal Information we collect about you for the following
            business purposes:
          </p>
          <h2 style={styles.subheader}>For providing you with our Service</h2>
          <p style={styles.paragraph}>
            We use the information you provide us to better provide you with our
            Service. This includes allowing you to log in to the Platform,
            operating and maintaining the Service, giving you access to your
            designs and billing you for transactions that you make via the
            Service.
          </p>
          <h2 style={styles.subheader}>
            To communicate with you about our Service
          </h2>
          <p style={styles.paragraph}>
            We use your contact information to send Service-related
            communications, such as communications regarding technical issues,
            transactional announcements, security concerns or administrative
            matters. We may send e-mails concerning any material change to this
            Privacy Policy and/or the Agreement, notification of an actual or
            suspected security breach that affects your Personal Information
            stored by or for the Company, or public relations announcements.
          </p>
          <h2 style={styles.subheader}>For improving our Service</h2>
          <p style={styles.paragraph}>
            We use information about you to help us improve our Service and our
            users' experience, including by monitoring aggregate metrics such as
            total page views, average amount of time spent on the Service,
            traffic, and demographic patterns.
          </p>
          <h2 style={styles.subheader}>
            For providing customer support services
          </h2>
          <p style={styles.paragraph}>
            We use information about you and that you provide to our customer
            support service team to resolve technical issues you experience with
            the Service and to ensure that we can fix and improve the Service
            for all our users.
          </p>
          <h2 style={styles.subheader}>For personalizing your experience</h2>
          <p style={styles.paragraph}>
            We use information you provide us to remember your preferences and
            identifying you or your device whenever you use our Service.
          </p>
          <h2 style={styles.subheader}>
            For advertising and marketing purposes
          </h2>
          <p style={styles.paragraph}>
            We use information about you, including cookies information and
            other similar technologies information we (and our third-party
            service providers) collect from you automatically, to serve and
            measure the effectiveness of advertising and marketing on the
            Service and third-party sites and platforms.
          </p>
          <h2 style={styles.subheader}>
            For security measures, enforcing our terms and conditions and
            preventing fraudulent activities
          </h2>
          <p style={styles.paragraph}>
            We use information about you to protect the integrity of our
            Service, our privacy and security and that of third parties, enforce
            this Privacy Policy and our Agreement and/or our other applicable
            terms and policies, and to prevent fraud and other illegal
            activities.
          </p>
          <h2 style={styles.subheader}>
            For matters that you have specifically provided your consent to
          </h2>
          <p style={styles.paragraph}>
            From time to time, we may seek your consent to use your information
            for a particular purpose. Where you have provided your consent to
            our using your information, we will use it for that particular
            purpose. Where you no longer want us to use your information for
            that particular purpose, you may retract your consent to this use.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>4. Sharing Your Personal Information</h1>
          <p style={styles.paragraph}>
            We share your Personal Information with third-party service
            providers so that we can provide the Service to you in an efficient
            and safe manner and to facilitate our legitimate interests. These
            third-party service providers will only be provided with access to
            your Personal Information as is reasonably necessary for the purpose
            that we have engaged such third-party service provider. Furthermore,
            we will require all our third-party service providers to agree to
            this Privacy Policy and any applicable laws.
          </p>
          <p style={styles.paragraph}>
            Some of the third parties which we may share your Personal
            Information with include third-party service providers who assist us
            with business functions such as the following: (i) Billing and
            payment processing; (ii) Customer support services; (iii) Email
            services; (iv) Hosting and storage; (v) Data analytics; (vi)
            Advertising and marketing services; and (vii) Other third-party
            service providers.
          </p>
          <h2 style={styles.subheader}>
            Who You Can Choose to Share your Personal Information and User
            Material With
          </h2>
          <p style={styles.paragraph}>
            Any Personal Information or User Material that you disclose on a
            voluntary basis for posting publicly to the Platform becomes
            available to be seen, collected and used by the public. When you set
            your profile to public or make Personal Information or User Material
            public, your information becomes publicly available worldwide,
            potentially searchable by other users and can be indexed by search
            engines. If you or the Company remove information that you posted to
            the Platform, copies may nonetheless remain viewable in cached and
            archived pages of the Platform, or if other users have copied or
            saved that information.
          </p>
          <h2 style={styles.subheader}>Sharing With Other Third Parties</h2>
          <p style={styles.paragraph}>
            We may also share Personal Information with third parties in certain
            circumstances, such as the following:
          </p>
          <ul>
            <li>
              <p style={styles.bulletpoint}>
                <span style={styles.bold}>
                  Collaborating with other Users on the Platform
                </span>{" "}
                - The Service enables Users to communicate and collaborate with
                each other, including allowing Users to engage in one-on-one
                communication using the text chat feature via Class Threads. Our
                servers may record, and we may retain records of the content of
                any such user-to-user communications.
              </p>
            </li>
            <li>
              <p style={styles.bulletpoint}>
                <span style={styles.bold}>
                  As required by law and in order to protect our rights
                </span>{" "}
                - We may share your Personal Information outside of the Platform
                if such sharing is required by law or, in our good faith belief,
                sharing is reasonably necessary to: (i) comply with legal
                process, such as a court order or a subpoena; (ii) cooperate
                with law enforcement; (iii) enforce this Privacy Policy, our
                Agreement, or other applicable terms and policies; (iv) respond
                to an emergency situation; or (v) protect the rights, property
                or safety of the Company, its users, or the public.
              </p>
            </li>
            <li>
              <p style={styles.bulletpoint}>
                <span style={styles.bold}>
                  Sale or transfer of all or part of our business
                </span>{" "}
                - We may transfer your Personal Information as part of our
                assets if we (or parts of our business) are sold, transferred
                to, merged with, or acquired by, a third party. Upon such
                transfer, the privacy policy of the acquiring entity may govern
                the use of your Personal Information. You will be notified by
                email and/or a prominent notice on the Platform if such a
                transaction takes place and be given notice of any material
                changes to the way we handle your Personal Information under the
                acquiring entity's privacy policy.
              </p>
            </li>
            <li>
              <p style={styles.bulletpoint}>
                <span style={styles.bold}>You Have Provided Consent</span> - We
                may share your Personal Information to other third parties you
                have consented to our sharing of it for a particular purpose.
              </p>
            </li>
          </ul>
          <div style={styles.divider} />
          <h1 style={styles.header}>5. Cookies</h1>
          <p style={styles.paragraph}>
            Cookies are small bits of information that are stored by your
            computer's web browser to collect standard Internet log information
            and visitor behavior patterns. When you use the Service, we may
            collect information from you automatically through cookies or
            similar technology. For further information, including how to see
            what cookies have been set and how to manage and delete them, visit
            www.aboutcookies.org or www.allaboutcookies.org.
          </p>
          <h2 style={styles.subheader}>How We User Cookies</h2>
          <p style={styles.paragraph}>
            We use cookies in various ways to improve your experience with the
            Service including the following:
          </p>
          <ul>
            <li>
              <p style={styles.bulletpoint}>
                Keeping you signed in on your account;
              </p>
            </li>
            <li>
              <p style={styles.bulletpoint}>
                To enhance the ease of use of the Service; and
              </p>
            </li>
            <li>
              <p style={styles.bulletpoint}>
                To gather statistics on how you use the Service.
              </p>
            </li>
          </ul>
          <h2 style={styles.subheader}>What Types of Cookies We Use</h2>
          <p style={styles.paragraph}>
            While there are a variety of cookie types, we use the following:
          </p>
          <ul>
            <li>
              <p style={styles.bulletpoint}>
                <span style={styles.bold}>Functionality Cookies</span> - We (and
                our third-party service providers) use this type of cookie so
                that we recognize that you are on our website and so that we can
                retain your previously chosen preferences. This type of cookie
                could include your preferences selected on your account and the
                location you are in. Both first-party cookies, which are served
                directly by us to your device, and third-party cookies, which
                are served by a third party on our behalf, are used.
              </p>
            </li>
            <li>
              <p style={styles.bulletpoint}>
                <span style={styles.bold}>Targeted Cookies</span> - We use this
                type of cookie to collect information about your visit to our
                website, the content you viewed, the links you followed and
                information pertaining to your internet browser, device, and
                your IP address.
              </p>
            </li>
          </ul>
          <h2 style={styles.subheader}>How to Manage Cookies</h2>
          <p style={styles.paragraph}>
            You may be able to refuse or disable cookies by adjusting your web
            browser settings. Because each web browser is different, please
            refer to the instructions provided by your web browser (usually
            provided in the “help” section of every browser). Useful links for
            browsers:
          </p>
          <p style={{ ...styles.paragraph, textAlign: "left" }}>
            <span style={styles.bold}>Internet Explorer:</span>{" "}
            <a
              href={
                "https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
              }
            >
              https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
            <br />
            <span style={styles.bold}>Google Chrome:</span>{" "}
            <a
              href={
                "https://support.google.com/chrome/answer/95647?hl=en&hlrm=en"
              }
            >
              https://support.google.com/chrome/answer/95647?hl=en&hlrm=en
            </a>
            <br />
            <span style={styles.bold}>Firefox:</span>{" "}
            <a
              href={
                "https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
              }
            >
              https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
            </a>
            <br />
            <span style={styles.bold}>Opera:</span>{" "}
            <a
              href={"https://help.opera.com/en/latest/web-preferences/#cookies"}
            >
              https://help.opera.com/en/latest/web-preferences/#cookies
            </a>
          </p>
          <p style={styles.paragraph}>
            Please note that if you choose to refuse, disable, or delete these
            cookies, some functionalities of the Service may no longer be
            available to you.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>6. Keeping Your Information Secure</h1>
          <p style={styles.paragraph}>
            Protecting the security of your Personal Information is important to
            us. We take reasonable measures to help protect Personal Information
            from loss, theft, unauthorized access, misuse, disclosure,
            alteration and destruction. However, you should understand that no
            data storage system or transmission of data over the Internet can be
            guaranteed to be one hundred percent (100%) secure. As a result,
            while we strive to protect your Personal Information, we cannot
            guarantee or warrant the security of any information you provide us
            or we collect about you. To protect the security of your Personal
            Information, we take reasonable steps to verify your identity before
            granting you access to your account. You can help us to keep
            Personal Information about you accurate by providing us with notice
            of any change to your e-mail address, mailing address or phone
            number.
          </p>
          <p style={styles.paragraph}>
            Furthermore, please note that information collected by third parties
            may not have the same security protections as information you submit
            to us, and we are not responsible for protecting the security of
            such information.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>7. Security Breaches</h1>
          <p style={styles.paragraph}>
            Should any Personal Information under our control be compromised due
            to a breach of security, the Company will take reasonable steps to
            investigate the situation and where appropriate, notify those
            individuals whose Personal Information may have been compromised and
            take other reasonable measures, in accordance with applicable laws
            and regulations.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>
            8. Your Rights and Choices About Your Personal Information
          </h1>
          <p style={styles.paragraph}>
            You have certain rights and choices with respect to your Personal
            Information we maintain about you. We will process your request as
            set forth below, however, our data retention policies and legal and
            audit obligations may require us to maintain some of your
            information (e.g., to be able to contact you to fulfill your
            requests).
          </p>
          <h2 style={styles.subheader}>
            You Control Your Account Information and Settings
          </h2>
          <p style={styles.paragraph}>
            You may access, update and delete your account information, if any,
            and your Personal Information contained therein, by logging into
            your account and changing your profile settings. If you have any
            questions about reviewing or modifying your account, you may contact
            us directly at{" "}
            <a href="mailto: support@studytable.co">support@studytable.co</a>.
          </p>
          <h2 style={styles.subheader}>
            Unsubscribing and Opting Out of Communications
          </h2>
          <p style={styles.paragraph}>
            You have the option to discontinue receiving communications from us.
            You may also withdraw your consent at any time to our collection,
            use and disclosure of your Personal Information subject to legal or
            contractual obligations and on reasonable notice. If you decide to
            withdraw your consent to our collection, use and disclosure of your
            Personal Information, we may not be able to provide you with all the
            services you may request or require from the Company.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>9. Personal Information Retention</h1>
          <p style={styles.paragraph}>
            To the extent permitted by applicable law, we may retain your
            profile information and User Material for a commercially reasonable
            time after termination or deactivation of your account with us, and
            for as long as we have a valid purpose to do so. In particular, we
            will retain your Personal Information in order to comply with our
            legal and audit obligations, in addition to backup and archival
            purposes.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>10. Children</h1>
          <p style={styles.paragraph}>
            Our Services are not intended to be used by individuals under the
            age of eighteen (18). We do not knowingly collect Personal
            Information from such individuals without parental consent and
            require our customers to fully comply with applicable law in the
            data collected from children under the age of eighteen (18). If you
            are a parent or guardian and believe your child has provided us with
            Personal Information without your consent, please contact us by
            using the information in Section 15 (How to Contact Us) below, and
            we will take measures to delete such Personal Information from our
            systems.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>
            11. Links to Third-Party Websites, Services and Products
          </h1>
          <p style={styles.paragraph}>
            The Service may contain links to websites, services or products
            offered by third parties outside the Company. We do not control
            these third-party websites, services or products. Accordingly, we
            are not responsible for the practices employed by websites, services
            or products linked to or from the Service, including the information
            or content contained therein. When you navigate away from the
            Service to another website, this Privacy Policy will no longer apply
            to third-party websites, services or products, and any information
            that the third party collects from or about you will be governed by
            the privacy policies and practices of that third party. You should
            always review and understand the privacy policies and practices of
            third parties before disclosing any Personal Information to those
            third parties. In addition, you acknowledge that we are not
            responsible for, and we do not exercise control over any third
            parties that you authorize to access your User Material.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>
            12. Using Our Service Outside the United States
          </h1>
          <p style={styles.paragraph}>
            The Company is based in the United States. The Service is controlled
            and operated by us from the United States and is intended to be used
            only by individuals located in the United States. If you are located
            outside of the United States and use the Service or otherwise
            provide Personal Information, you understand and agree that any
            Personal Information that you provide or that we collect from you
            may be transferred to, stored and processed on servers located
            outside your resident jurisdiction, including the United States, or
            in any country in which we engage service providers.
          </p>
          <p style={styles.paragraph}>
            The data privacy and protection laws in the United States may not be
            as comprehensive as those in your jurisdiction. By using the Service
            or otherwise providing us with your Personal Information, you
            acknowledge that you fully understand and consent to: (i) the
            transfer and storage of your Personal Information in servers located
            outside your resident jurisdiction and, to the extent you are a
            resident of a country other than the United States, that you consent
            to the transfer of such data to the United States; and (ii) the
            processing of your Personal Information in accordance with this
            Privacy Policy.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>13. California Users' Privacy Rights</h1>
          <p style={styles.paragraph}>
            This Section 13 applies only to California residents and describes
            how we collect, use and share Personal Information of such residents
            in the operation of our Service and such residents' rights with
            respect to that Personal Information.
          </p>
          <h2 style={styles.subheader}>Your California Privacy Rights</h2>
          <p style={styles.paragraph}>
            California users of our Service have additional rights afforded to
            them under the California Consumer Privacy Act (CCPA). For purposes
            of this Section 13, “Personal Information” shall have the meaning
            ascribed in the CCPA. Notwithstanding the foregoing, this Section 13
            shall not apply: (i) to information that has been exempted from the
            scope of the CCPA; (ii) except as otherwise required by the CCPA, to
            Personal Information we collect from individuals acting in their
            capacities as representatives of organizations solely in the context
            of conducting due diligence regarding, or providing or receiving
            product or service to or from, such organizations; or (iii) to
            Personal Information we collect, use and share on behalf of our
            customers as a “service provider” as defined under the CCPA for
            purposes of providing the Service to our customers. We do not “sell”
            (as defined in the CCPA) the Personal Information of our users.
          </p>
          <h2 style={styles.subheader}>
            Categories of Personal Information We Collect
          </h2>
          <p style={styles.paragraph}>
            For more details about the Personal Information the Company has
            collected during the preceding twelve (12) months, please see
            Section 2 (Information We Collect and Why). For details on how we
            use that information, and who that information is shared with,
            please see Section 3 (How Your Information is Used) and Section 4
            (Sharing Your Information).
          </p>
          <h2 style={styles.subheader}>How to Exercise Your Rights</h2>
          <p style={styles.paragraph}>
            The CCPA provides California consumers various rights with respect
            to the Personal Information we collect, including the right to
            (subject to certain limitations):
          </p>
          <ul>
            <li>
              <p style={styles.bulletpoint}>
                Request to access the Personal Information we have about you;
                and
              </p>
            </li>
            <li>
              <p style={styles.bulletpoint}>
                Request that we delete all your Personal Information.
              </p>
            </li>
          </ul>
          <p style={styles.paragraph}>
            California users may make a request by contacting us at{" "}
            <a href="mailto: support@studytable.co">support@studytable.co</a>.
            We will authenticate your request using the email address associated
            with your Company account and if necessary, proof of residency.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>14. Changes to Our Privacy Policy </h1>
          <p style={styles.paragraph}>
            We may update this Privacy Policy from time to time to reflect our
            current practice and ensure compliance with applicable laws. If we
            change our Privacy Policy, we will update the “Last Updated” date at
            the top of this webpage. We recommend that you review this Privacy
            Policy from time to time to stay informed of the latest
            modifications.
          </p>
          <div style={styles.divider} />
          <h1 style={styles.header}>15. Changes to Our Privacy Policy</h1>
          <p style={styles.paragraph}>
            If you have any questions about this Privacy Policy or the Service,
            if you have a complaint, or would like to contact us, please contact
            us at{" "}
            <a href="mailto: support@studytable.co">support@studytable.co</a>.
          </p>
        </div>
      </div>
      <div style={{ marginTop: 100 }} />
      <Footer />
    </div>
  );
}

import React from "react";
import useWindowDimensions from "../functions/useWindowDimensions";
import { AiOutlineDesktop } from "react-icons/ai";
import colors from "../config/colors";

export default function RequireDesktop() {
  const { height, width } = useWindowDimensions();

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AiOutlineDesktop color={colors.grey} size={60} />
      <p
        style={{
          fontFamily: "Montserrat-SemiBold",
          color: colors.grey,
          maxWidth: "90%",
          width: 400,
          marginTop: 10,
        }}
      >
        Please use a desktop or enlargen your browser window to access the
        Studytable dashboard.
      </p>
    </div>
  );
}

import useWindowDimensions from "../functions/useWindowDimensions";

export default function Footer() {
  const { height, width } = useWindowDimensions();

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#040A14",
        height: 150,
        justifyContent: width > 700 ? "space-between" : "center",
        flexDirection: width > 700 ? "row" : "column",
        paddingRight: "4%",
        paddingLeft: "4%",
        alignItems: "center",
      }}
    >
      <div style={{ flexDirection: width > 700 ? "row" : "column" }}>
        <a href="/terms" style={{ textDecoration: 'none' }}>
          <p
            style={{
              fontFamily: "Montserrat-SemiBold",
              color: "#fff",
              marginRight: width > 700 ? 30 : 0,
              fontSize: 14,
              marginBottom: 5,
              opacity: 0.65
            }}
          >
            TERMS
          </p>
        </a>
        <a href="/privacy" style={{ textDecoration: 'none' }}>

        <p
          style={{
            fontFamily: "Montserrat-SemiBold",
            color: "#fff",
            opacity: 0.65,
            fontSize: 14,
            marginBottom: 5,
          }}
        >
          PRIVACY POLICY
        </p>
        </a>

      </div>

      <p
        style={{
          fontFamily: "Montserrat-SemiBold",
          color: "#fff",
          opacity: 0.65,
          fontSize: 14,
          marginBottom: 5,
        }}
      >
        STUDYTABLE™
      </p>
    </div>
  );
}

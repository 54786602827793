import Logo from "../components/Logo";
import colors from "../config/colors";

export default function Navbar(props) {
  const isAlternate = props.alternate;
  return (
    <div style={{ zIndex: 1000, width: "100%" }}>
      <nav
        style={{
          width: "100%",
          height: 100,
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: isAlternate ? colors.colorOne : "transparent",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "4%",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <Logo alternate={isAlternate}/>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginRight: "4%",
            zIndex: 100,
          }}
        >
          {/*
          <div style={{display: 'flex', flexDirection: 'row', marginRight: 20}}>
            <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 10, paddingBottom: 10, borderRadius: 50, justifyContent: "center", alignItems: 'center', display: 'flex'}}>
              <p style={{color: "white", fontFamily: "Montserrat-SemiBold", fontSize: '14px', marginTop: 0, marginBottom: 0}} >HOME</p>
            </div>
          </div>

         
          <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 10, paddingBottom: 10, border: '2px solid white', borderRadius: 50, justifyContent: "center", alignItems: 'center', display: 'flex'}}>
            <p style={{color: "white", fontFamily: "Montserrat-SemiBold", fontSize: '14px', marginTop: 0, marginBottom: 0}} >SIGN UP</p>
          </div>
        */}
        </div>

        {/*<Link to="/">Home</Link> |{" "}
        <Link to="about">About</Link>
        <Link to="contact">Contact Us</Link>*/}
      </nav>
    </div>
  );
}

import React, { useState } from "react";
import useWindowDimensions from "../functions/useWindowDimensions";
import LoginPhoto from "../assets/images/LoginPhoto.jpeg";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import Logo from "../components/Logo";
import colors from "../config/colors";
import RequireDesktop from "../dashboardComponents/RequireDesktop";
import { ref as db_ref, get, getDatabase, query } from "firebase/database";
import { getEmailEnding, getUsername } from "../functions/emailHelpers";

export default function Login() {
  const auth = getAuth();
  const db = getDatabase();

  const [formType, setFormType] = useState("signin");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [error, setError] = useState("");

  const { height, width } = useWindowDimensions();

  const styles = {
    btn: {
      width: "100%",
      cursor: "pointer",
      height: 45,
      backgroundColor: "#273758",
      borderRadius: 10,
      marginTop: 50,
      justifyContent: "center",
      alignItems: "center",
    },
    btnText: {
      color: "white",
      fontFamily: "Montserrat-SemiBold",
    },
    container: {
      height: "100vh",
      minHeight: 700,
      justifyContent: "center",
      alignItems: "center",
    },
    errorText: {
      color: colors.red,
      textAlign: "center",
      width: "100%",
      marginTop: 10,
      fontFamily: "Montserrat-Medium",
      height: 20,
    },
    formContainer: {
      width: 375,
      marginTop: 50,
    },
    header: {
      textAlign: "left",
      width: "100%",
      fontFamily: "Montserrat-SemiBold",
      color: colors.colorOne,
    },
    input: {
      color: "#15233b",
      width: "100%",
      height: 45,
      borderRadius: 10,
      marginTop: 15,
    },
    leftContainer: {
      width: "50%",
      height: "100%",
      background: `url(${LoginPhoto})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    logoContainer: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    rightContainer: {
      height: "100%",
      width: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    registerHere: {
      color: colors.orange,
      fontFamily: "Montserrat-SemiBold",
      cursor: "pointer",
    },
    noAcctText: {
      textAlign: "center",
      width: "100%",
      marginTop: 20,
      fontFamily: "Montserrat-Medium",
    },
  };

  const login = () => {
    const cleanedEmail = email.trim().toLowerCase();
    const isEmailValid = String(cleanedEmail).match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (!isEmailValid) {
      setError("Please enter a valid email.");
      return;
    }

    if (!password) {
      setError("Please enter your password.");
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // signed in
      })
      .catch((error) => {
        if (error.code == "auth/user-not-found") {
          setError("Invalid credentials.");
        } else {
          console.log(error)
          setError("An error has occured.");
        }
      });
  };

  const signup = async () => {
    const cleanedEmail = email.trim().toLowerCase();
    const isEmailValid = String(cleanedEmail).match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (!isEmailValid) {
      setError("Please enter a valid email.");
      return;
    }

    if (!password) {
      setError("Please enter a password.");
      return;
    }

    if (password != rePassword) {
      setError("Passwords don't match.");
      return;
    }

    const emailEnding = getEmailEnding(cleanedEmail);
    const username = getUsername(cleanedEmail);

    const userDataRef = db_ref(db, "userData/" + emailEnding + "/" + username);
    const userDataSnapshot = await get(query(userDataRef));

    if (userDataSnapshot.val()) {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            setError("Email already in use.");
          } else {
            setError("An error has occured.");
          }
        });
    } else {
      setError("This email is not registered to use our platform.");
      return;
    }
  };

  const loginForm = () => {
    return (
      <div>
        <div style={styles.logoContainer}>
          <Logo />
        </div>
        <div style={styles.formContainer}>
          <h2 style={styles.header}>Log In</h2>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            style={styles.input}
          />
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            style={styles.input}
          />
          <button onClick={login} style={styles.btn}>
            <p style={styles.btnText}>Submit</p>
          </button>
          <small>
            <p style={styles.errorText}>{error}</p>
          </small>
        </div>
      </div>
    );
  };

  const signupForm = () => {
    return (
      <div>
        <div style={styles.logoContainer}>
          <Logo />
        </div>
        <div style={styles.formContainer}>
          <h2 style={styles.header}>Sign Up</h2>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            style={styles.input}
          />
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            style={styles.input}
          />
          <input
            value={rePassword}
            onChange={(e) => setRePassword(e.target.value)}
            type="password"
            placeholder="Re-enter Password"
            style={styles.input}
          />
          <button onClick={signup} style={styles.btn}>
            <p style={styles.btnText}>Submit</p>
          </button>
          <small>
            <p style={styles.noAcctText}>
              Already have an account?{" "}
              <span
                onClick={() => {
                  setError("");
                  setFormType("signin");
                }}
                style={styles.registerHere}
              >
                Sign in here
              </span>
            </p>
            <p style={styles.errorText}>{error}</p>
          </small>
        </div>
      </div>
    );
  };

  if (width > 900) {
    return (
      <div style={{ ...styles.container, flexDirection: "row" }}>
        <div style={styles.leftContainer}></div>
        <div style={styles.rightContainer}>
          {formType == "signin" && loginForm()}
        </div>
      </div>
    );
  } else if (width > 700) {
    return (
      <div style={styles.container}>
        {formType == "signin" && loginForm()}
      </div>
    );
  } else {
    return <RequireDesktop />;
  }
}
